import * as React from 'react';
import { AppBar, Stack, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { SettingsButton } from './SettingsButton';
export default function Navigation() {
  return (
    <AppBar position="static">
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={2}>
          <Stack direction="row" alignItems="flex-start" gap={1} sx={{ pr: 3 }}>
            <DashboardIcon fontSize="large" />
            <Typography
              variant="h6"
              align="right"
              color="inherit"
              sx={{ flexGrow: 1 }}
            >
              עלמא סחר
            </Typography>
          </Stack>
          <Typography
            align="right"
            variant="body2"
            component="div"
            sx={{ flexGrow: 1, pr: 3 }}
          >
            מערכת ניהול מוצרים
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              paddingTop: 1,
              height: 50,
              marginTop: '10px',
              '> a': {
                color: 'white',
                width: '150px',
                textAlign: 'center',
              },
            }}
          >
            <NavLink to="/imports">ייבוא וריאנטים</NavLink>
            <NavLink to="/importLiat">ייבוא מכירות חודשי</NavLink>
            <NavLink to="/changeBase">שינוי מחיר עלות ידני</NavLink>
            <NavLink to="/sapakim">ספקים רשימת תפוצה</NavLink>
            <NavLink to="/">דוח מאגר מרכזי</NavLink>
            <NavLink style={{ width: 170 }} to="/liatreport">
              דוח מכירות חודשי מרוכז
            </NavLink>
            <NavLink style={{ width: 170 }} to="/NonActiveVariants">
              דוח וריאנטים לא פעילים
            </NavLink>
            <NavLink to="/amlot">תעוד עמלות מערכת</NavLink>
            <NavLink to="/fixmissing">השלמת שדות חסרים</NavLink>
            <SettingsButton />
            {/* <NavLink to="/reports" color="inherit">דוחות</NavLink> */}
          </Stack>
        </Grid>
      </Grid>
    </AppBar>
  );
}

import { BrowserRouter } from 'react-router-dom';

import AppRouter from './router';
import { AuthProvider } from './hooks/useAuth';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider, createTheme } from '@mui/material';
import { heIL } from '@mui/x-data-grid';

const queryClient = new QueryClient({
  defaultOptions: {},
});
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;

export const columns = [
  {
    field: 'variant_id',
    headerName: 'מס וריאנט',
    width: 130,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'variant_name',
    headerName: 'שם וריאנט',
    width: 170,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'base_price',
    headerName: 'מחיר בסיס',
    width: 80,
    type: 'number',
    editable: false,
    hide: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'cost_price',
    headerName: 'מחיר עלות',
    width: 80,
    type: 'number',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'org_price',
    headerName: 'מחיר לארגון',
    width: 80,
    type: 'number',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'hatava_number',
    headerName: 'מס דף הטבה',
    width: 80,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'hatava_name',
    headerName: 'שם דף הטבה',
    width: 130,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'sapak_name',
    headerName: 'שם ספק',
    width: 130,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'delivery_days',
    headerName: 'ימי אספקה',
    width: 70,
    type: 'number',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'makat',
    headerName: 'מקט',
    width: 80,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'hovala',
    headerName: 'הובלה',
    width: 70,
    editable: false,
    sortable: true,
    filterable: true,
  },

  {
    field: 'createdAt',
    headerName: 'תאריך יצירה',
    width: 100,
    type: 'date',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'updatedAt',
    headerName: 'תאריך עדכון',
    width: 100,
    type: 'date',
    hide: true,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'changedManualy',
    headerName: 'שונה מחיר ידנית',
    width: 100,
    hide: true,
    editable: false,
    sortable: true,
    filterable: true,
    cellClassName: 'custom-cell-editble border font-weight-bold',
  },
  {
    field: 'active',
    headerName: 'האם פעיל',
    width: 100,
    editable: false,
    sortable: true,
    filterable: true,
    cellClassName: 'custom-cell-editble border font-weight-bold',
  },
  {
    field: 'gain_prec_kochavit',
    headerName: 'רווח',
    width: 100,
    hide: true,
    editable: false,
    sortable: true,
    filterable: true,
    cellClassName: 'custom-cell-editble border font-weight-bold',
  },
];

import React, { useEffect, useState } from 'react';

import {
  DataGridPro as DataGrid,
  GridToolbar,
  heIL,
} from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import {
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
} from '@mui/material';
import axiosInstance from '../../Utils/apiBaseUrl';
import Swal from 'sweetalert2';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { saveAs } from 'file-saver';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

function ImportLiatPrev({ data }) {
  const [selectionModel, setSelectionModel] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [checkQueueStatus, setCheckQueueStatus] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  //toggle group
  const [variantsSatate, setVariantsState] = useState('all');
  const [rowDataForDB, setRowDataForDB] = useState([]);
  const [selectedRowDict, setSelectedRowsDict] = useState({});
  const [dataDict, setDataDict] = useState({});

  useEffect(() => {
    const pollServer = () => {
      axiosInstance
        .get('/liat/check-queue')
        .then((response) => {
          console.log('response of queue ', response);
          if (response.status === 200) {
            axiosInstance
              .get('/liat/download', {
                responseType: 'blob',
              })
              .then((response) => {
                saveAs(response.data, 'liat-reports.zip');
                setCheckQueueStatus(false);
                setBussyImporting(false);
              })
              .catch((error) => {
                setCheckQueueStatus(false);
                setBussyImporting(false);
                console.log('error during download data ', error);
              });
          } else {
            console.log('inside the else');
            setTimeout(pollServer, 20 * 1000);
          }
        })
        .catch((error) => {
          setCheckQueueStatus(false);
          setBussyImporting(false);
          console.log('error during fetch on data ', error);
        });
    };

    if (checkQueueStatus) {
      setTimeout(pollServer, 20 * 1000);
    }
  }, [checkQueueStatus]);

  //build grid from data
  useEffect(() => {
    if (!data.length > 0) {
      return;
    }

    setSelection([]);
    setBussyImporting(true);
    setcolumns([]);
    setRows([]);
    setRowDataForDB([]);

    // Columns  GOLMI
    let theHeaders = Object.keys(data[0]).map((key) => key);
    //3 5 6 7 10 11 12 13 14 16 22 26 29

    const thecolumns = theHeaders.map((itm, index) => {
      let tmpObj = {
        field: itm,
        headerName: itm.replace('RecordID', 'מספר הזמנה'),
        width: 90, //104
        editable: false,
        sortable: true,
        filterable: true,

        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };

      if (index === 12) {
        // tmpObj.type = "number"
        // tmpObj.sortable = true
        tmpObj.width = 100;
      }
      if ((index === 0) | (index === 1) | (index === 14)) {
        tmpObj.type = 'date';
      }
      if (
        index === 8 ||
        index === 9 ||
        index === 10 ||
        index === 12 ||
        index === 13
      ) {
        tmpObj.type = 'number';
      }
      if (index === 14) {
        tmpObj.width = 120;
      }
      return tmpObj;
    });
    setcolumns(thecolumns);

    //######   Rows  GOLMI #############################################

    let rowDataForDBTmp = [];
    const arrayData = Object.values(data).map((value) => {
      const obj = {
        ...value,
        'תאריך הזמנה': new Date((value['תאריך הזמנה'] - 25569) * 86400 * 1000)
          .toLocaleString('en-GB')
          .substring(0, 10),
        'תאריך הזמנה מלא': new Date(
          (value['תאריך הזמנה'] - 25569) * 86400 * 1000
        ),
        'תאריך מימוש': new Date((value['תאריך מימוש'] - 25569) * 86400 * 1000)
          .toLocaleString('en-GB')
          .substring(0, 10),
        'תאריך מימוש מלא': new Date(
          (value['תאריך מימוש'] - 25569) * 86400 * 1000
        ),
        id: uuidv4(),
      };
      return obj;
    });
    const arrayDataDict = arrayData.reduce((acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {});
    setDataDict(arrayDataDict);
    const dataDict = arrayData.reduce((acc, item) => {
      const liatObject = {
        id: item.id,
        order_date: item['תאריך הזמנה מלא'],
        mimush_date: item['תאריך מימוש מלא'],
        customer_id: item['קוד לקוח'],
        sapak_name: item['שם ספק'],
        sapak_id: item['קוד ספק'],
        product_id: item['קוד מוצר'],
        product_name: item['שם מוצר'],
        shovarim: item['כמות שוברים'],
        total_sales: item['סך מכירות'],
        order_number: item['RecordID'],
        base_price: item['מחיר בסיס'],
        sapak_katze_payment: item['סך מכירות'],
        sapak_katze_payment_from_file: item['סך מכירות'],
      };
      acc[item.id] = liatObject;
      return acc;
    }, {});
    setSelectedRowsDict(dataDict);

    setRowDataForDB(rowDataForDBTmp);
    setRows(arrayData);
    setBussyImporting(false);
  }, [reloadall, data]);

  const importToDbYesNo = (importData) => {
    Swal.fire({
      title: 'האם אתה בטוח?',
      text: 'הרשומות שבחרת יתווספו למאגר המרכזי',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'כן, העלה למסד נתונים',
      cancelButtonText: 'לא, בטל',
    }).then((result) => {
      if (result.value) {
        if (importData) {
          importLiatToDb();
        } else {
          saveLiatDataToDb();
        }
      } else {
        setSystemMsg('');
        setBussyImporting(false);
      }
    });
  };

  // save all the liat data reports and wait for trigger
  const saveLiatDataToDb = async () => {
    setSystemMsg(
      'מעדכן מסד נתונים...פעולה זאת עשוייה לקחת כחצי דקה עד דקה ... '
    );
    setBussyImporting(true);

    try {
      await axiosInstance.delete('/liat');
      const data = Array.from(selection);
      const promises = [];
      while (data.length) {
        const batch = data.splice(0, 2000);
        promises.push(
          axiosInstance.post('/liat', {
            data: batch,
          })
        );
      }

      const responses = await Promise.all(promises);
      const newLiatOrders = responses.reduce((acc, response) => {
        const arrayOfLiatOrders = response.data;
        const liatOrders = arrayOfLiatOrders.map((order) => dataDict[order.id]);
        const tempAcc = acc.concat(liatOrders);
        return tempAcc;
      }, []);
      setRows(newLiatOrders);
      setSystemMsg(`נמצאו ${newLiatOrders.length} וריאנטים חדשים`);
      Swal.fire({
        title: `נמצאו ${newLiatOrders.length} וריאנטים חדשים`,
      });
      setBussyImporting(false);
    } catch (error) {
      setBussyImporting(false);
      Swal.fire({ title: ` an error occured ${error}` });
      setSystemMsg(`an error occured ${error}`);

      console.log(`an error occured ${error}`);
    }
  };

  // send all the liat data reports to all the suppliers
  const importLiatToDb = async () => {
    setSystemMsg(
      'מעדכן מסד נתונים...פעולה זאת עשוייה לקחת כחצי דקה עד דקה ... '
    );
    setBussyImporting(true);

    try {
      const data = Array.from(selection);
      const promises = [];
      await axiosInstance.delete('/liat');
      while (data.length) {
        const batch = data.splice(0, 2000);
        promises.push(
          axiosInstance.post('/liat', {
            data: batch,
          })
        );
      }
      const responses = await Promise.all(promises);
      const newLiatOrders = responses.reduce((acc, response) => {
        const arrayOfLiatOrders = response.data;
        const liatOrders = arrayOfLiatOrders.map((order) => dataDict[order.id]);
        const tempAcc = acc.concat(liatOrders);
        return tempAcc;
      }, []);
      setRows(newLiatOrders);
      setSystemMsg(`נמצאו ${newLiatOrders.length} וריאנטים חדשים`);
      Swal.fire({
        title: `נמצאו ${newLiatOrders.length} וריאנטים חדשים`,
      });
      try {
        const res = await axiosInstance.get('/liat/adjust');
        console.log('adjust queue and create excel files ', res.data);
      } catch (error) {
        console.log('there was an error with adjust ', error);
      }
      setCheckQueueStatus(true);
    } catch (error) {
      setBussyImporting(false);
      Swal.fire({ title: ` an error occured ${error}` });
      setSystemMsg(`an error occured ${error}`);

      console.log(`an error occured ${error}`);
    }
  };
  const handleChange = async (e) => {
    setSystemMsg(
      'מעדכן מסד נתונים...פעולה זאת עשוייה לקחת כחצי דקה עד דקה ... '
    );
    setBussyImporting(true);

    let newVariantsSatate = e.target.value;
    console.log('handleChange ', e.target.value);
    setVariantsState(newVariantsSatate);
    console.log(newVariantsSatate);
    switch (newVariantsSatate) {
      case 'all':
        alert('all button');
        // unSelectAllRows()
        // console.log('variantsSatate show all...')
        // setReloadall(!reloadall)
        // setSystemMsg("כל הוריאנטים מוצגים")
        break;
      case 'filtered':
        alert('filtered button');
        // console.log('variantsSatate call filterExistingVariants...')

        break;
      case 'sendLiatData':
        importToDbYesNo(true);
        break;
      case 'saveToDb':
        importToDbYesNo(false);
        break;
      default:
        break;
    }
  };

  const handleCellEdit = (e) => {
    console.log('handleCellEdit ', e); //use id to chabge the row in rows array
    let fieldEN = '';

    switch (e.field) {
      case 'הובלה':
        console.log('הובלה');
        fieldEN = 'hovala';
        break;
      default:
        fieldEN = '';
        break;
    }

    let EditedRows = [...rows];

    rows.map((row, index) => {
      if (row.id === e.id) {
        EditedRows[index] = { ...row, [e.field]: e.value, [fieldEN]: e.value };
      }
    });
    setRows(EditedRows);
    setSelection(EditedRows);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ height: 550, width: '100%' }}>
        <Typography variant="h6">ייבוא ליאת</Typography>
        <ToggleButtonGroup
          color="primary"
          value={variantsSatate}
          exclusive
          onChange={handleChange}
        >
          <Tooltip arrow placement="top" title="ייבוא אל מאגר ליאת">
            <ToggleButton
              disabled={bussyImporting}
              sx={{
                borderBottomLeftRadius: '20px!important',
                background: '#1976d2',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              value="saveToDb"
            >
              שליחת דוח תקין לאחר בדיקה
              <FileOpenIcon />
            </ToggleButton>
          </Tooltip>
          <Tooltip arrow placement="top" title="שלח דוח ליאת לכל הספקים">
            <ToggleButton
              disabled={bussyImporting}
              sx={{
                borderBottomLeftRadius: '20px!important',
                background: '#1976d2',
                marginRight: '1120px',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              value="sendLiatData"
            >
              בדיקת דוח חודשי
              <FileOpenIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>

        {selection && selection.length > 0 ? (
          <Typography variant="body1" color="#1976d2">
            {selection.length} רשומות נבחרו
          </Typography>
        ) : (
          <>
            <Typography variant="body1" color="primary">
              0 רשומות נבחרו
            </Typography>
          </>
        )}
        <Typography
          gutterBottom={true}
          align="center"
          variant="caption"
          color="secondery"
        >
          {systemMsg}
        </Typography>
        {bussyImporting && <LinearProgress sx={{ my: 1 }} />}

        {data.length > 0 && (
          <DataGrid
            onCellEditCommit={handleCellEdit}
            //  localeText={{
            components={{
              Toolbar: GridToolbar,
            }}
            pagination
            sx={{
              fontSize: 13,
              height: '800px',
              '& .MuiTablePagination-actions': {
                direction: 'ltr !important',
              },
              '& .MuiDataGrid-footerContainer': {
                direction: 'ltr !important',
              },
            }}
            density="compact"
            rows={rows}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
            disableSelectionOnClick
            // onSelectionModelChange={(newSelectionModel) => {
            //     setSelection(newSelectionModel);
            // }}

            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = [];
              selectedIDs.forEach((id) => {
                selectedRowData.push(selectedRowDict[id]);
              });
              setSelectionModel(ids);
              setSelection([...selectedRowData]);
            }}
            {...data}
            selectionModel={selectionModel}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default ImportLiatPrev;

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import {
  DataGridPro as DataGrid,
  GridToolbar,
  heIL,
} from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import {
  Container,
  Typography,
  LinearProgress,
  Stack,
  FormControl,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Swal from 'sweetalert2';
//drop down months

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

export const Amlot = () => {
  const [data, setData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);

  const [repMonth, setRepMonth] = useState('01');
  const [repYear, setRepYear] = useState('2021');

  //data to datagrid
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }

    console.log('FX build grid ', data);
    setSelection([]);
    setBussyImporting(true);
    let thecolumns = [];

    //Columns
    const theHeadersHEB = [
      'תאריך',
      'ספק',
      'מס ספק',
      'תשלום לספק קצה מליאת',
      'תשלום לספק קצה שנשלח ',
      ' סכום חשבונית',
      'רווח כוכבית',
      'הערות',
    ];

    let theHeadersKeysENG = [
      'report_received_date',
      'sapak_name',
      'sapak_id',
      'sapak_katze_payment_from_file_sum', //LiatData Schema
      'sapak_katze_payment_sum', //?
      'invoice_number',
      'gain_prec_kochavit', //calc: col1 - col2
      'remarks',
    ];
    // console.log(theHeadersKeysENG)
    let theHeadersKeys = [];
    theHeadersKeysENG.map((x) => {
      console.log(x);
      if (x === 'active' || x === '_id' || x === 'updatedAt') {
        return;
      } else {
        theHeadersKeys.push(x);
      }
    });

    console.log(theHeadersKeys);

    theHeadersKeys.map((itm, index) => {
      let tmpObj = {
        field: itm,
        headerName: theHeadersHEB[index],
        width: 140,
        editable: false,
        sortable: true,
        filterable: true,

        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };

      if (index === 0) {
        tmpObj.type = 'date';
      }
      if ((index === 3) | (index === 4) | (index === 6)) {
        tmpObj.type = 'number';
        tmpObj.sortable = true;
        tmpObj.width = 160;
      }
      if (index === 5 || index === 7) {
        tmpObj.editable = true;
        tmpObj.width = 140;
      }

      thecolumns.push({ ...tmpObj });
    });
    setcolumns(thecolumns);

    //#### Rows #######################################################

    let tmpArray = [];

    data.map((itm, index) => {
      let row = {};
      // theHeadersKeys.map((header, index) => {

      row = itm;
      row = {
        // hierarchy: ['itm._liat_product_id.report_received_date'],

        ...row,
        id: itm.sapak_id,
        gain_prec_kochavit: 'xxx',
        report_received_date: new Date(itm.report_received_date),
        sapak_katze_payment_from_file_sum:
          itm.sapak_katze_payment_from_file_sum,
        sapak_katze_payment_sum: itm.sapak_katze_payment_sum,
        remarks: itm.remarks,
        invoice_number: itm.invoice_number,

        gain_prec_kochavit:
          itm.sapak_katze_payment_from_file_sum - itm.sapak_katze_payment_sum,
      };

      // })
      //console.log(row)
      tmpArray.push(row);
    });

    setRows(tmpArray);
    setBussyImporting(false);
  }, [reloadall, data]);

  //hierarchy ?
  const getTreeDataPath = (row) => row.hierarchy;

  //loadData
  const loadData = async () => {
    setBussyImporting(true);
    const response = await axiosInstance.post('/liat/amlot', {
      repMonth,
      repYear,
    });
    console.log(response.data);

    if (response.data !== 'no data') {
      setData(response.data);
    } else {
      Swal.fire({
        title: 'תוצאות',
        text: 'לא נמצאו רשומות תואמות לתאריך זה',
        timer: 1000,
      });
      setBussyImporting(false);
    }
  };

  //export data to csv
  const exportData = () => {
    axiosInstance
      .get('/api/exportData')
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCellEdit = (e) => {
    // console.log("handleCellEdit ", e.field) //use id to chabge the row in rows array
    //alert(e)
    let fieldEN = '';

    switch (e.field) {
      case 'invoice_number':
        console.log('invoice_number');
        fieldEN = 'invoice_number';
        break;
      case 'remarks':
        console.log('remarks');
        fieldEN = 'remarks';
        break;

      default:
        fieldEN = '';
        break;
    }

    let EditedRows = [...rows];
    let dataObj = {};

    rows.map((row, index) => {
      if (row.id === e.id) {
        switch (e.field) {
          case 'invoice_number':
            // alert(e.value)
            EditedRows[index][fieldEN] = e.value;
            setRows(EditedRows);
            setSelectionModel([]);
            setSelection([]);
            dataObj = {
              report_received_date: row.report_received_date,
              sapak_id: row.sapak_id,
              sapak_name: row.sapak_name,
              invoice_number: e.value,
              remarks: row.remarks,
            };
            updateDB(dataObj);

            break;
          case 'remarks':
            EditedRows[index][fieldEN] = e.value;
            setRows(EditedRows);
            setSelectionModel([]);
            setSelection([]);
            dataObj = {
              report_received_date: row.report_received_date,
              sapak_id: row.sapak_id,
              sapak_name: row.sapak_name,
              invoice_number: row.invoice_number,
              remarks: e.value,
            };
            updateDB(dataObj);

            break;

          default:
            break;
        }
      }
    });
  };
  const updateDB = async (amlotData) => {
    //console.log(data)
    const response = await axiosInstance.post('/amlot', { amlotData });
    console.log(response.data);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        {/* <HomeHeaders /> */}

        <Typography marginRight={2} variant="caption" color="blue">
          {systemMsg}
        </Typography>

        <div
          className="drops-container"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
          }}
        >
          <div className="year-select" style={{ width: '33%', padding: '5' }}>
            <FormControl fullWidth>
              <InputLabel id="month-select-label">חודש</InputLabel>
              <Select
                labelId="month-select-label"
                id="month-select"
                value={repMonth}
                label="חודש"
                onChange={(e) => setRepMonth(e.target.value)}
              >
                <MenuItem value="01">ינואר</MenuItem>
                <MenuItem value="02">פברואר</MenuItem>
                <MenuItem value="03">מרץ</MenuItem>
                <MenuItem value="04">אפריל</MenuItem>
                <MenuItem value="05">מאי</MenuItem>
                <MenuItem value="06">יוני</MenuItem>
                <MenuItem value="07">יולי</MenuItem>
                <MenuItem value="08">אוגוסט</MenuItem>
                <MenuItem value="09">ספטמבר</MenuItem>
                <MenuItem value="10">אוקטובר</MenuItem>
                <MenuItem value="11">נובמבר</MenuItem>
                <MenuItem value="12">דצמבר</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="month-select" style={{ width: '33%', padding: '5' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">שנה</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={repYear}
                label="שנה"
                onChange={(e) => setRepYear(e.target.value)}
              >
                <MenuItem value="2020">2020</MenuItem>
                <MenuItem value="2021">2021</MenuItem>
                <MenuItem value="2022">2022</MenuItem>
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
                <MenuItem value="2026">2026</MenuItem>
                <MenuItem value="2027">2027</MenuItem>
                <MenuItem value="2028">2028</MenuItem>
                <MenuItem value="2029">2029</MenuItem>
                <MenuItem value="2030">2030</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="load-data-btn" style={{ width: '33%', padding: '5' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => loadData()}
            >
              טען נתונים
            </Button>
          </div>
        </div>

        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            // getTreeDataPath={getTreeDataPath}
            // treeData

            components={{
              LoadingOverlay: LinearProgress,

              Toolbar: GridToolbar,

              NoRowsOverlay: () =>
                bussyImporting ? (
                  <>
                    <LinearProgress />
                    טוען רשומות...
                  </>
                ) : (
                  !data || (data.length === 0 && <>לא נמצאו תוצאות</>)
                ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  אין תוצאות
                </Stack>
              ),
            }}
            sx={{ fontSize: 13 }}
            density="compact"
            rows={rows}
            columns={columns}
            pageSize={16}
            rowsPerPageOptions={[16]}
            checkboxSelection
            disableSelectionOnClick
            onCellEditCommit={handleCellEdit}
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id.toString())
              );
              console.log(selectedRowData.length);
              if (selectedRowData.length > 0) {
                setSystemMsg(selectedRowData.length + ' רשומות נבחרו');
              } else {
                setSystemMsg('לא נבחרו רשומות');
              }
              setSelectionModel(ids);
              setSelection([...selectedRowData]);
            }}
            {...data}
            selectionModel={selectionModel}
          />
        </div>
      </Container>
    </ThemeProvider>
  );
};

import { CssBaseline } from '@mui/material';
import React from 'react';
import Navigation from './Navigation/Navbar';
import { Outlet } from 'react-router-dom';

export const AppLayout = () => {
  return (
    <>
      <CssBaseline />
      <Navigation />
      <Outlet />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import {
  DataGridPro as DataGrid,
  GridToolbar,
  heIL,
} from '@mui/x-data-grid-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  Button,
} from '@mui/material';
import axiosInstance from '../../Utils/apiBaseUrl';
import * as longRequests from '../../Utils/longRequests.js';
import Swal from 'sweetalert2';
import SyncIcon from '@mui/icons-material/Sync';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Stack } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { utils, writeFile } from 'xlsx';
import { defaultTitle } from './utils.js';

longRequests.init();

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

function ImportVeriantsPreview({ data, checked }) {
  const [selectionModel, setSelectionModel] = useState([]);

  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [selectedRowsDict, setSelectedRowsDict] = useState({});
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');

  const isDeltaFile = checked;

  //toggle group
  const [variantsSatate, setVariantsState] = React.useState('all');
  const [progress, setProgress] = React.useState(0);
  const [rowStatusChanged, setRowStatusChanged] = React.useState(false);

  const [curRow, setCurRow] = React.useState(null);

  const [rowStatus, setRowStatus] = useState({
    hovala: 0,
    basePrice: 0,
    amla: 0,
    gain_prec_kochavit: 0.0,
    lastFiledChanged: 0,
    hovalaBasis: 0,
    curVariantId: 0,
    org_price: 0,
  });

  const [serverIsRunning, setServerIsRunning] = useState(false);
  longRequests.listenAny(setServerIsRunning);

  useEffect(() => {
    setRows([]);
    setcolumns([]);
    return () => {};
  }, [checked]);

  //build grid
  useEffect(() => {
    //console.log(isDeltaFile)
    if (!data.length > 0) {
      return;
    }
    setSelection([]);
    setBussyImporting(true);

    //Columns

    const thecolumns = [];
    console.log('data ', data[0]);
    const theHeaders = Object.keys(data[0]).map((key) => key);
    theHeaders.forEach((itm, index) => {
      console.log('itm ', itm);
      console.log('index ', index);
      if (itm.includes('ובסיס')) {
        return;
      }
      const tmpObj = {
        ...defaultTitle,
        field: itm,
        headerName: itm,
        filter: {
          ...defaultTitle.filter,
          placeholder: 'חיפוש לפי ' + itm,
        },
      };

      if (
        index === 5 ||
        index === 6 ||
        index === 7 ||
        index === 9 ||
        index === 10 ||
        index === 11
      ) {
        tmpObj.type = 'number';
        tmpObj.sortable = true;
        tmpObj.width = 120;
        tmpObj.align = 'left';
        tmpObj.direction = 'left';
      }
      if (index === 5 || index === 6) {
        tmpObj.type = 'number';
        tmpObj.sortable = true;
        tmpObj.width = 90;
      }

      if (index === 9 || index === 11 || index === 7) {
        // hoval and base_price edit
        tmpObj.editable = true;
        tmpObj.sortable = true;
        // tmpObj.cellClassName = "custom-cell-editble"
        tmpObj.cellClassName = (params) => {
          // //console.log("params value ", params)
          if (!params || !params?.value) return 'custom-cell-gain';

          if (params?.value?.toString().includes('*')) {
            return 'cell-edited';
          }
        };
      }

      if (
        index === 0 ||
        index === 1 ||
        index === 2 ||
        index === 3 ||
        index === 4 ||
        index === 5 ||
        index === 8 ||
        index === 9 ||
        index === 13
      ) {
        tmpObj.width = 90;
        tmpObj.cellClassName = 'hide-cell';
        tmpObj.editable = false;
      }

      thecolumns.push({ ...tmpObj });
    });

    if (!isDeltaFile) {
      console.log(' it is GOLMI file');
    } else {
      console.log(' it is DELTA file');
      console.log(' thecolumns length', thecolumns.length);
      if (thecolumns.length === 12) {
        //remove last 3 columns
        thecolumns.splice(9, 3);
        console.log(' thecolumns length after splice', thecolumns.length);
      }

      //hovala
      thecolumns.push({
        field: 'hovala',
        headerName: 'הובלה',
        width: 80, //125
        editable: true,
        sortable: true,
        filterable: true,
        align: 'left',
        headerAlign: 'left',
        // cellClassName: "custom-cell-editble"// orig
        cellClassName: (params) => {
          // //console.log("params value ", params)
          if (!params || !params?.value) return 'custom-cell-editble';

          if (params?.value?.toString().includes('*')) {
            return 'cell-edited';
          }
        },

        headerClassName: 'datagrid-custom-header',
        type: 'number',
        filter: {
          type: 'number',
          placeholder: 'חיפוש לפי הובלה ',
        },
      });

      //רווח כוכבית
      thecolumns.push({
        field: 'gain_prec_kochavit',
        headerName: 'רווח כוכבית',
        align: 'left',
        headerAlign: 'left',
        width: 90, //125
        editable: false,
        sortable: true,
        filterable: true,
        cellClassName: 'custom-cell-editble',
        headerClassName: 'datagrid-custom-header',
        type: 'number',
        filter: {
          type: 'number',
          placeholder: 'חיפוש לפי אחוז רווח למוצר כוכבית ',
        },
      });

      //  עמלה
      thecolumns.push({
        field: 'amla',
        headerName: 'עמלה',
        width: 120, //125
        editable: true,
        sortable: true,
        filterable: true,
        align: 'left',
        headerAlign: 'left',
        valueFormatter: (params) => ` ${params?.value} `,
        // cellClassName: "custom-cell-editble",
        cellClassName: (params) => {
          if (!params || !params?.value) return 'custom-cell-editble';
          if (params?.value?.toString().includes('*')) {
            return 'cell-edited';
          }
        },
        headerClassName: 'datagrid-custom-header',
        type: 'number',

        filter: {
          type: 'number',
          placeholder: 'חיפוש לפי עמלה ',
        },
      });

      if (!thecolumns[12]?.field?.includes('ובסיס')) {
        //  הובלה + בסיס מחיר
        thecolumns.push({
          field: 'base_n_hov',
          headerName: 'הובלה ובסיס',
          width: 95, //125
          editable: false,
          sortable: true,
          filterable: true,
          align: 'left',
          headerAlign: 'left',
          cellClassName: 'custom-cell',
          //headerClassName: "datagrid-custom-header",
          type: 'number',
          filter: {
            type: 'number',
            placeholder: 'הובלה + בסיס ',
          },
        });
      }
    }

    setcolumns(thecolumns);

    //############   Rows    ##########################################

    let tmpArray = [];

    // console.log("headers ", headers)
    // console.log("1 row  ", dataMinusHeaders[0])
    data.forEach((itm) => {
      let basepricecol = parseInt(
        itm[theHeaders[6]].toString().replace(',', '').replace('*', '')
      );
      // נוסחה ((org - hovala) / (base - hovala)) - (org - hovala)

      //no hovala yet so:
      //  ((org ) / (base )) - (org )

      let gain_prec_kochavit = (
        (parseInt(itm[theHeaders[5]]) - parseInt(basepricecol)) /
        parseInt(itm[theHeaders[5]])
      ).toFixed(3);

      if (isDeltaFile) {
        // replace the * in the values
        // //console.log(' YES it is a tmpfile');
        let baseInt = parseInt(itm[theHeaders[6]]);
        let hovalaInt = parseInt(itm[theHeaders[9]]);

        // "מס' דף הטבה" 0
        // "שם דף הטבה"1
        // "מס' וריאנט"2
        // "שם וריאנט"3
        // "שם ספק"4
        // "מחיר לארגון"5
        // "מחיר בסיס"6
        // "מחיר עלות"7
        // "ימי אספקה"8
        // " מקט"9
        //9"הובלה"
        //10 "רווח כוכבית"
        //11: "עמלה"

        tmpArray.push({
          id: itm[theHeaders[2]],
          [theHeaders[0]]: itm[theHeaders[0]], //מס' דף הטבה
          [theHeaders[1]]: itm[theHeaders[1]], //"שם דף הטבה"
          [theHeaders[2]]: itm[theHeaders[2]], //מס' וריאנט
          [theHeaders[3]]: itm[theHeaders[3]], //שם וריאנט
          [theHeaders[4]]: itm[theHeaders[4]], //שם ספק
          [theHeaders[5]]: itm[theHeaders[5]], //מחיר לארגון
          [theHeaders[6]]: itm[theHeaders[6]], //מחיר בסיס
          [theHeaders[7]]: itm[theHeaders[7]], //מחיר עלות
          [theHeaders[8]]: itm[theHeaders[8]], //ימי אספקה
          [theHeaders[9]]: itm[theHeaders[9]], //מקט
          hatava_number: itm[theHeaders[0]],
          hatava_name: itm[theHeaders[1]],
          variant_id: itm[theHeaders[2]],
          variant_name: itm[theHeaders[3]],
          sapak_name: itm[theHeaders[4]],
          org_price: itm[theHeaders[5]],
          base_price: itm[theHeaders[6]],
          cost_price: itm[theHeaders[7]],
          delivery_days: itm[theHeaders[8]],
          makat: itm[theHeaders[9]],
          hovala: itm[theHeaders[10]] || 0,
          gain_prec_kochavit: gain_prec_kochavit,
          amla: 0.0,
          base_n_hov: baseInt | (0 + hovalaInt) | 0,
        });
      } else {
        let baseInt = parseInt(itm[theHeaders[6]]);
        let hovalaInt = parseInt(itm[theHeaders[9]]);
        tmpArray.push({
          id: itm[theHeaders[2]],
          [theHeaders[0]]: itm[theHeaders[0]],
          [theHeaders[1]]: itm[theHeaders[1]],
          [theHeaders[2]]: itm[theHeaders[2]],
          [theHeaders[3]]: itm[theHeaders[3]],
          [theHeaders[4]]: itm[theHeaders[4]],
          [theHeaders[5]]: itm[theHeaders[5]],
          [theHeaders[6]]: itm[theHeaders[6]],
          [theHeaders[7]]: itm[theHeaders[6]],
          [theHeaders[8]]: itm[theHeaders[8]],
          [theHeaders[9]]: itm[theHeaders[9]],
          הובלה: 0,
          עמלה: 0,

          hatava_number: itm[theHeaders[0]],
          hatava_name: itm[theHeaders[1]],
          variant_id: itm[theHeaders[2]],
          variant_name: itm[theHeaders[3]],
          sapak_name: itm[theHeaders[4]],
          org_price: itm[theHeaders[5]],
          base_price: itm[theHeaders[6]],
          cost_price: itm[theHeaders[6]],
          delivery_days: itm[theHeaders[7]],
          makat: itm[theHeaders[8]],
          hovala: 0,
          gain_prec_kochavit: gain_prec_kochavit,
          base_n_hov: baseInt | (0 + hovalaInt) | 0,
        });
      }
    });
    tmpArray.sort((a, b) => (a.sapak_name > b.sapak_name ? 1 : -1));
    setRows(tmpArray);
    const dataDict = tmpArray.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});
    setSelectedRowsDict(dataDict);
    setBussyImporting(false);
  }, [reloadall, data]);

  const exportToExcel = () => {
    if (!selection || selection.length === 0) {
      Swal.fire({
        title: 'אנא בחר פריטים ליצוא',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'סגור',
      });
      return;
    }
    const theHeaders = Object.keys(data[0]).map((key) => key);
    const excelData = selection.map((row) => {
      return {
        [theHeaders[0]]: row[theHeaders[0]], //מס' דף הטבה
        [theHeaders[1]]: row[theHeaders[1]], //"שם דף הטבה"
        [theHeaders[2]]: row[theHeaders[2]], //מס' וריאנט
        [theHeaders[3]]: row[theHeaders[3]], //שם וריאנט
        [theHeaders[4]]: row[theHeaders[4]], //שם ספק
        [theHeaders[5]]: row[theHeaders[5]], //מחיר לארגון
        [theHeaders[6]]: row[theHeaders[6]], //מחיר בסיס
        [theHeaders[7]]: row[theHeaders[6]], //מחיר עלות
        [theHeaders[8]]: row[theHeaders[8]], //ימי אספקה
        [theHeaders[9]]: row[theHeaders[9]], //מקט
      };
    });
    const todayDate = new Date().toLocaleString('en-GB').substring(0, 10);
    const worksheet = utils.json_to_sheet(excelData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Delta');
    worksheet['!cols'] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    writeFile(workbook, `Deltafile-${todayDate}.xlsx`, { compression: true });
  };
  //listen to rowState changes triggered by rowNumSelectedChanged
  useEffect(() => {
    //console.log("FX1 curRow ", curRow)
    //get fileds from curretn row and set them to rowStatus

    if (curRow === null || curRow === undefined) {
      //console.log("return 2")
      return;
    }

    if (rows.length <= 0) {
      //console.log("return 3")
      return;
    }
    //console.log("continue")

    setRowStatus({
      ...rowStatus,
      costPrice: rows[curRow]?.cost_price,
      hovala: rows[curRow]?.hovala,
      amla: parseFloat(rows[curRow]?.amla) | 0,
      hovalaBasis:
        parseInt(rows[curRow]?.cost_price) + parseInt(rows[curRow].hovala),
      gain_prec_kochavit: parseInt(rows[curRow]?.gain_prec_kochavit) | 0,
    });
    setRowStatusChanged(!rowStatusChanged);
  }, [curRow]);

  //fx 2
  useEffect(() => {
    //console.log("FX2 triggered by rowStatusChanged")
    if (!rows || rows.length <= 0 || !rowStatus.curVariantId) return;

    if (selection.length > 1) {
      console.log('###   multi selection   ####');
      return;
    }

    //console.log(`put row ${curRow} values into rowStatus and chage curRow from ${curRow} to ${curRow}`)
    //console.log("basePrice: rows[curRow].base_price ", rows[curRow].base_price)

    let basePriceCleaned = rowStatus?.costPrice?.toString().replace('*', '');
    let tmpHovala = rowStatus?.hovala?.toString().replace('*', '');
    let amlaClean = rowStatus?.amla?.toString().replace('*', '');
    let gain_prec_kochavit_Calced;

    if (tmpHovala >= 1) {
      gain_prec_kochavit_Calced = (
        (rows[curRow].org_price - tmpHovala - (basePriceCleaned - tmpHovala)) /
        (rows[curRow].org_price - tmpHovala)
      ).toFixed(3);
    } else {
      gain_prec_kochavit_Calced = (
        (rows[curRow]?.org_price - basePriceCleaned) /
        rows[curRow].org_price
      ).toFixed(3);
    }

    //console.log("gain_prec_kochavit_Calced ", gain_prec_kochavit_Calced)
    let re_hovala_and_Basis, re_basePrice, gain_prec_kochavit_re_Calced;

    switch (rowStatus.lastFiledChanged) {
      case 'hovala':
        if (tmpHovala === 0 || tmpHovala === null) {
          //hovala === 0 | null
          //console.log("hovala === 0")
          setRowStatus({
            ...rowStatus,
            hovala: 0,
            hovalaBasis: parseInt(basePriceCleaned),
            gain_prec_kochavit: gain_prec_kochavit_Calced,
            amla: amlaClean,
          });
          break;
        }

        if (tmpHovala <= 0 || basePriceCleaned <= 0) {
          //hovala <= 0
          //console.log("hovala or base price <= 0")
          break;
        }

        // //console.log("calculate hovala + basis ")
        // //console.log(rowStatus.basePrice)
        // //console.log(rowStatus.hovala)

        setRowStatus({
          ...rowStatus,
          hovalaBasis: parseInt(tmpHovala) + parseInt(basePriceCleaned),
          gain_prec_kochavit: gain_prec_kochavit_Calced,
        });

        break;

      case 'costPrice':
        if (tmpHovala <= 0) {
          setRowStatus({
            ...rowStatus,
            hovalaBasis: parseInt(basePriceCleaned).toFixed(3),
            gain_prec_kochavit: gain_prec_kochavit_Calced,
          });
        } else {
          setRowStatus({
            ...rowStatus,
            hovalaBasis: parseInt(tmpHovala) + parseInt(basePriceCleaned),
            gain_prec_kochavit: gain_prec_kochavit_Calced,
          });
        }

        break;
      //new_Base_Price => (org_price - hovala ) * amla

      case 'amla':
        if (rowStatus.amla <= 0) return;
        console.log('amla changed ' + rowStatus.amla);
        //re calcs
        if (tmpHovala >= 1) {
          console.log('amla changed and hovala = ' + tmpHovala);
          re_basePrice = parseInt(rows[curRow].org_price) * amlaClean;
          console.log('re_basePrice: ' + re_basePrice);
          re_hovala_and_Basis = parseInt(tmpHovala) + parseInt(re_basePrice);
          gain_prec_kochavit_re_Calced = (
            (rows[curRow].org_price -
              tmpHovala -
              (parseInt(re_basePrice) - tmpHovala)) /
            (rows[curRow].org_price - tmpHovala)
          ).toFixed(3);
        } else {
          //hovala 0 or null
          console.log('amla changed and hovala = ' + tmpHovala);
          re_basePrice = parseInt(rows[curRow].org_price) * amlaClean;
          console.log('re_basePrice: ' + re_basePrice);
          re_hovala_and_Basis = parseInt(re_basePrice);

          gain_prec_kochavit_re_Calced = (
            (rows[curRow].org_price - parseInt(re_basePrice)) /
            rows[curRow].org_price
          ).toFixed(3);
        }
        basePriceCleaned = re_basePrice;

        console.log(
          'gain_prec_kochavit_re_Calced with new amla ',
          gain_prec_kochavit_re_Calced
        );

        setRowStatus({
          ...rowStatus,

          costPrice: re_basePrice,
          hovalaBasis: re_hovala_and_Basis,
          gain_prec_kochavit: gain_prec_kochavit_re_Calced,
          org_price: rows[curRow].org_price,
        });
        break;

      default:
        break;
    }
    if (!re_basePrice) {
      re_basePrice = basePriceCleaned;
    }

    // console.log(`1 HERE:Calced  ${gain_prec_kochavit_Calced},re_Calced  ${gain_prec_kochavit_re_Calced} `)

    updateRowInGrid(
      re_basePrice,
      basePriceCleaned,
      tmpHovala,
      gain_prec_kochavit_re_Calced,
      gain_prec_kochavit_Calced
    );

    return () => {};
  }, [rowStatusChanged]);

  const updateRowInGrid = (
    re_basePrice,
    basePriceCleaned,
    tmpHovala,
    gain_prec_kochavit_re_Calced,
    gain_prec_kochavit_Calced
  ) => {
    let curRowWithChanges = {
      ...rows[curRow],
      gridClassName: 'wasedited',
      [Object.keys(rows[curRow])[8]]: re_basePrice || basePriceCleaned,
      cost_price: re_basePrice || basePriceCleaned,
      amla: parseFloat(rowStatus.amla) + '*',
      hovala: parseInt(tmpHovala) + '*',
      gain_prec_kochavit:
        gain_prec_kochavit_re_Calced || gain_prec_kochavit_Calced,
      base_n_hov:
        parseInt(re_basePrice || basePriceCleaned) + parseInt(tmpHovala) + '*',
    };
    //console.log("update the row with all changes AFTER: ", rows[curRow])
    // console.log('row after changes: ', curRowWithChanges)

    let tmpRows = rows.map((itm, index) => {
      if (index === curRow) {
        return curRowWithChanges;
      } else {
        return itm;
      }
    });
    // console.log('curRowWithChanges ', curRowWithChanges);
    setRows(tmpRows);
  };

  const changeAmlaMulti = (theAmla, curVariantId) => {
    let tmpRows = [];
    let amlaClean = theAmla;

    selection.map((rowItem, index) => {
      console.log('amlaClean  ', amlaClean);
      let basePriceCleaned = rowItem?.cost_price?.toString().replace('*', '');
      let tmpHovala = rowItem?.hovala?.toString().replace('*', '');

      let gain_prec_kochavit_Calced;
      let gain_prec_kochavit_re_Calced;
      let orgPrice = rowItem.org_price;
      let re_basePrice;
      let re_hovala_and_Basis;

      // console.log("basePriceCleaned", basePriceCleaned)
      // console.log("tmpHovala", tmpHovala)
      // console.log("amlaClean", amlaClean)
      // console.log("orgPrice", orgPrice)

      if (tmpHovala >= 1) {
        gain_prec_kochavit_Calced = (
          (orgPrice - tmpHovala - (basePriceCleaned - tmpHovala)) /
          (orgPrice - tmpHovala)
        ).toFixed(3);
      } else {
        gain_prec_kochavit_Calced = (
          (orgPrice - basePriceCleaned) /
          orgPrice
        ).toFixed(3);
      }

      // re calcs
      if (tmpHovala >= 1) {
        re_basePrice = parseInt(orgPrice) * amlaClean;

        re_hovala_and_Basis = parseInt(tmpHovala) + parseInt(re_basePrice);
        gain_prec_kochavit_re_Calced = (
          (orgPrice - tmpHovala - (parseInt(re_basePrice) - tmpHovala)) /
          (orgPrice - tmpHovala)
        ).toFixed(3);
      } else {
        //hovala 0 or null

        re_basePrice = parseInt(orgPrice) * amlaClean;

        re_hovala_and_Basis = parseInt(re_basePrice);

        gain_prec_kochavit_re_Calced = (
          (orgPrice - parseInt(re_basePrice)) /
          orgPrice
        ).toFixed(3);
      }

      basePriceCleaned = re_basePrice;

      //update rows

      let curRowWithChanges = {
        // ...rows[index],
        ...rowItem,

        [Object.keys(rowItem)[7]]: re_basePrice || basePriceCleaned,
        cost_price: re_basePrice || basePriceCleaned,

        amla: parseFloat(amlaClean) + '*',

        hovala: parseInt(tmpHovala) + '*',

        gain_prec_kochavit:
          gain_prec_kochavit_re_Calced || gain_prec_kochavit_Calced,

        base_n_hov:
          parseInt(re_basePrice || basePriceCleaned) +
          parseInt(tmpHovala) +
          '*',
      };

      tmpRows.push(curRowWithChanges);
    });

    const ids = new Set(tmpRows.map((d) => d.id));
    const merged = [...tmpRows, ...rows.filter((d) => !ids.has(d.id))];

    console.log('merged ', merged);

    setRows(merged);

    unSelectAllRows();
  };

  useEffect(() => {
    //console.log(rows)
    return () => {};
  }, [rows]);

  //calcs handleCellEdit
  const handleCellEdit = (e) => {
    let fieldEN = '';

    switch (e.field) {
      case 'hovala':
        fieldEN = 'hovala';
        break;
      case 'מחיר עלות':
        fieldEN = 'costPrice';
        break;
      case 'amla':
        fieldEN = 'amla';
        break;
      default:
        fieldEN = '';
        break;
    }

    //console.log(`field  ${fieldEN} changed  ${e.value}`)

    switch (fieldEN) {
      case 'hovala':
        setRowStatus({
          ...rowStatus,
          hovala: parseInt(e.value) || 0,
          lastFiledChanged: fieldEN,
          curVariantId: e.id,
        });

        break;
      case 'amla':
        if (selection.length > 1) {
          setRowStatus({
            ...rowStatus,
            amla: parseFloat(e.value) || 0,
            lastFiledChanged: fieldEN,
            curVariantId: e.id,
          });
          let theAmla = parseFloat(e.value);
          let curVariantId = e.id;

          return changeAmlaMulti(theAmla, curVariantId);
        } else {
          //console.log("amla e value ", e.value)
          setRowStatus({
            ...rowStatus,
            amla: parseFloat(e.value) || 0,
            lastFiledChanged: fieldEN,
            curVariantId: e.id,
          });
        }

        break;

      case 'costPrice':
        setRowStatus({
          ...rowStatus,
          costPrice: e.value || 0,
          lastFiledChanged: fieldEN,
          curVariantId: e.id,
        });
        break;
      default:
        break;
    }
    //trigger firts fx
    setRowStatusChanged(!rowStatusChanged);
    return;
  };

  // const updateBasePrice = async () => {
  //   if (selection.length === 0) {
  //     Swal.fire('לא נבחרו רשומות');
  //     return;
  //   }

  //   setBussyImporting(true);
  //   try {
  //     const data = Array.from(selection);
  //     const promises = [];
  //     while (data.length) {
  //       const batch = data.splice(0, 2000);
  //       const formatBatch = batch.map((item) => ({
  //         variant_id: item.variant_id,
  //         base_price: item.base_price,
  //       }));
  //       promises.push(
  //         axiosInstance.post('/variants/updateBasePrice', {
  //           variants: formatBatch,
  //         })
  //       );
  //     }

  //     await Promise.all(promises);

  //     setBussyImporting(false);

  //     unSelectAllRows();
  //   } catch (error) {
  //     console.log('error', error);
  //     setSystemMsg(error);
  //     Swal.fire(error);
  //   }
  // };
  const checkNewVariants = async () => {
    if (selection.length === 0) {
      Swal.fire('לא נבחרו רשומות');
      return;
    }

    setBussyImporting(true);
    try {
      const data = Array.from(selectionModel);
      const promises = [];
      await axiosInstance.post('/variants/mark_non_active');
      while (data.length) {
        const batch = data.splice(0, 2000);
        promises.push(
          axiosInstance.post('/variants/checkNewVariants/', {
            variants: batch,
          })
        );
      }
      const responses = await Promise.all(promises);
      const newVariants = responses.reduce((acc, response) => {
        const arrayOfIds = response.data;
        const variantsArray = arrayOfIds.map((id) => selectedRowsDict[id]);
        const tempAcc = acc.concat(variantsArray);
        return tempAcc;
      }, []);

      setBussyImporting(false);
      console.log('newVariants ', newVariants);
      setSystemMsg(
        `נמצאו ${newVariants ? newVariants.length : 0} וריאנטים חדשים`
      );
      Swal.fire(`נמצאו ${newVariants ? newVariants.length : 0} וריאנטים חדשים`);
      setRows(newVariants);
      unSelectAllRows();
    } catch (error) {
      console.log('error', error);
      setSystemMsg(error);
      Swal.fire(error);
    }
  };

  const importToDb = () => {
    Swal.fire({
      title: 'האם אתה בטוח?',
      text: 'הרשומות שבחרת יתווספו למאגר המרכזי',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'כן, העלה למסד נתונים',
      cancelButtonText: 'לא, בטל',
    }).then((result) => {
      if (result.value) {
        importToDbPost();
      }
    });
  };

  const importToDbDelta = () => {
    Swal.fire({
      title: 'האם אתה בטוח?',
      text: 'הרשומות שבחרת יתווספו למאגר המרכזי',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'כן, העלה למסד נתונים',
      cancelButtonText: 'לא, בטל',
    }).then((result) => {
      if (result.value) {
        importToDbPostDelte();
      }
    });
  };

  const importToDbPost = async () => {
    try {
      setBussyImporting(true);
      let response = await axiosInstance.post('/variants/import_to_db/', {
        variants: selection,
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          //console.log("percentCompleted ", percentCompleted / 2)
          setProgress(percentCompleted / 2);
        },
      });
      setBussyImporting(false);
      //console.log(response.data)
      response.data.length
        ? Swal.fire({
            title: `הוספו למאגר המרכזי  ${response.data.length} רשומות`,
          })
        : Swal.fire({ title: `לא ניתן לייבא רשומת שכבר קיימות במאגר ` });
    } catch (error) {
      //console.log(error)
    }
  };

  const importToDbPostDelte = async () => {
    console.log('posting...', selection);

    try {
      //console.log('posting...', selection)
      setBussyImporting(true);

      //remove asterix from base_price async
      console.log(selection);

      let response = await axiosInstance.post('/variants/import_to_db_delta/', {
        variants: selection,
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          //console.log("percentCompleted ", percentCompleted / 2)
          setProgress(percentCompleted / 2);
        },
      });
      setBussyImporting(false);
      // //console.log(response.data)
      Swal.fire({ title: `הוספו למאגר המרכזי רשומות` });
    } catch (error) {
      //console.log(error)
    }
  };

  const unSelectAllRows = () => {
    setSelection([]);
    setSelectionModel([]);
  };

  const handleChange = (e) => {
    let newVariantsSatate = e.target.value;
    //console.log("handleChange ", e.target.value)
    setVariantsState(newVariantsSatate);
    console.log(newVariantsSatate);
    switch (newVariantsSatate) {
      case 'all':
        unSelectAllRows();
        //console.log("variantsSatate show all...")
        setReloadall(!reloadall);
        setSystemMsg('כל הוריאנטים מוצגים');
        break;
      case 'importToDb':
        if (selection && selection.length > 0) {
          importToDb();
        } else {
          Swal.fire({
            title: 'אנא בחר פריטים לייבוא',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'סגור',
          });
        }

        break;

      case 'importToDbDelta':
        if (selection && selection.length > 0) {
          importToDbDelta();
        } else {
          Swal.fire({
            title: 'אנא בחר פריטים לייבוא',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'סגור',
          });
        }

        break;

      case 'checkNewVariants':
        // console.log('restoreVars ');
        checkNewVariants();
        break;
      // case 'updateBasePrice':
      //   updateBasePrice();
      //   break;

      default:
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        className="container-big"
        style={{
          height: '650px',
          background: '#E8E8E8',
        }}
      >
        {serverIsRunning && <h6>השרת עובד, אנא המתן לפני פעולות נוספות</h6>}
        <ToggleButtonGroup
          color="primary"
          value={variantsSatate}
          exclusive
          onClick={handleChange}
          disabled={serverIsRunning}
        >
          {!isDeltaFile && (
            <>
              <Tooltip
                arrow
                placement="top"
                title="הצגת כל הוריאנטים המופיעים בקובץ ושבהם שם הספק מכיל עלמא"
              >
                <ToggleButton
                  disabled={bussyImporting}
                  sx={{ borderBottomRightRadius: '20px!important' }}
                  value="all"
                >
                  הצג את כל הוריאנטים
                  <VisibilityIcon color="blueLight" sx={{ mr: 1 }} />
                </ToggleButton>
              </Tooltip>
              <Tooltip
                arrow
                placement="top"
                title=" וריאנטים מהדטה המרכזי שאינם פעילים אך מופיעים בקובץ זה  יסומנו שוב *כן* פעילים "
              >
                <ToggleButton value="checkNewVariants">
                  מצא את הוריאנטים החדשים בקובץ - אינם נמצאים במאגר המרכזי
                  <SyncIcon color="blueLight" sx={{ mr: 1 }} />
                </ToggleButton>
              </Tooltip>
            </>
          )}
          <Tooltip
            arrow
            placement="top"
            title="  ייבוא של תצוגה נוכחית אל תוך המאגר המרכזי"
          >
            <ToggleButton
              disabled={bussyImporting}
              sx={{
                background: '#1976d2',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              value="importToDb"
            >
              ייבא למאגר המרכזי
              <FileOpenIcon />
            </ToggleButton>
          </Tooltip>

          <Tooltip
            arrow
            placement="top"
            title="  ייבוא של תצוגה נוכחית אל תוך המאגר המרכזי"
          >
            <ToggleButton
              disabled={bussyImporting}
              sx={{
                borderBottomLeftRadius: '20px!important',
                background: '#1556d0',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              value="importToDbDelta"
            >
              ייבא דלתא בלבד למאגר המרכזי
              <FileOpenIcon />
            </ToggleButton>
            {/* <ToggleButton
              disabled={bussyImporting}
              sx={{
                borderBottomLeftRadius: '20px!important',
                background: '#1556d0',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'black',
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
              value="updateBasePrice"
            >
              עדכן מחיר בסיס
              <FileOpenIcon />
            </ToggleButton> */}
          </Tooltip>
        </ToggleButtonGroup>

        {selection && selection.length > 0 ? (
          <Typography variant="body1" color="#1976d2">
            {selection.length} רשומות נבחרו
          </Typography>
        ) : (
          <>
            <Typography variant="body1" color="primary">
              0 רשומות נבחרו
            </Typography>
          </>
        )}

        <Typography
          gutterBottom={true}
          align="center"
          variant="caption"
          color="secondery"
        >
          {systemMsg}
        </Typography>

        {bussyImporting && (
          <>
            <LinearProgress sx={{ my: 1 }} />
            <LinearProgress
              color="success"
              variant="determinate"
              value={progress}
            />
          </>
        )}
        <Typography variant="body1" color="primary">
          {curRow && parseInt(curRow + 1)}
        </Typography>
        <Typography variant="body1" color="primary">
          {isDeltaFile ? 'קובץ דלתא' : 'קובץ גולמי'}
        </Typography>
        {!isDeltaFile && (
          <Button
            sx={{
              background: '#1976d2',
              color: 'white',
              '&:hover': {
                backgroundColor: 'black',
                opacity: [0.9, 0.8, 0.7],
              },
            }}
            onClick={exportToExcel}
          >
            ייצא לאקסל
          </Button>
        )}
        <DataGrid
          components={{
            Toolbar: GridToolbar,
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                טוען רשומות
              </Stack>
            ),
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                אין תוצאות
              </Stack>
            ),
          }}
          onCellEditCommit={handleCellEdit}
          cell--editable
          pagination
          sx={{
            width: 1 / 1.1,
            mr: 1,
            fontSize: 13,
            height: '800px',
            '& .MuiTablePagination-actions': {
              direction: 'ltr !important',
            },
            '& .MuiDataGrid-footerContainer': {
              direction: 'ltr !important',
            },
            [`& .edited`]: {
              backgroundColor: 'orange',
            },
          }}
          density="compact"
          rows={rows}
          columns={columns}
          getRowClassName={(params) =>
            params.row.gridClassName && params.row.gridClassName === 'wasedited'
              ? 'edited'
              : ''
          }
          pageSize={100}
          rowsPerPageOptions={[100]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            //clean all "*"
            let selectedRowDataClean = JSON.parse(
              JSON.stringify(selectedRowData).replaceAll('*', '')
            );

            setSelectionModel(ids);

            setSelection([...selectedRowDataClean]);
          }}
          {...data}
          selectionModel={selectionModel}
          onRowClick={(val) => {
            const curRow = rows.findIndex((itm) => itm.id === val.id);
            setCurRow(curRow);
          }}
        />
      </div>
    </ThemeProvider>
  );
}

export default ImportVeriantsPreview;

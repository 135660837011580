import React, { useState, useEffect } from 'react';
import axiosInstance from '../Utils/apiBaseUrl';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useCSVReader } from 'react-papaparse';
import LinearProgress from '@mui/material/LinearProgress';
import { Stack } from '@mui/material';

export const Fixmissing = () => {
  const { CSVReader } = useCSVReader();

  const [fixed, setFixed] = useState([]);
  const [fileData, setFileData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!fileData.length > 0) {
      return;
    }

    setLoading(true);
    let colLength = fileData[0].length;
    //14 is delta
    console.log(' col length : ', colLength);
    if (colLength >= 10) {
      //delta
      console.log('DELTA col length : ', colLength);
      setLoading(false);
      return;
    } else {
      console.log('GOMI col length : ', colLength);

      setLoading(false);
    }

    //Columns
    const thecolumns = [];
    fileData[0].map((itm, index) => {
      if (index > 8) return;
      let tmpObj = {
        align: 'left',
        field: itm,
        headerName: itm,
        headerAlign: 'left',
        headerClassName: 'datagrid-custom-header',
        width: 95, //125
        editable: true,
        sortable: true,
        filterable: true,

        cellClassName: 'custom-cell',

        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };
      thecolumns.push({ ...tmpObj });
    });
    //add end filed name to the columns
    // thecolumns.map((itm, index) => {
    //   console.log("itm", itm.headerName)
    // })

    setcolumns(thecolumns);

    //Rows #####################
    //     let headers = fileData[0]
    //    // console.log("headers", headers)
    let dataMinusHeaders = fileData.slice(1);
    let tmpArray = [];
    dataMinusHeaders = fileData.slice(1);

    dataMinusHeaders.map((itm) => {
      tmpArray.push({
        id: itm[2],
        "מס' דף הטבה": itm[0],
        'שם דף הטבה': itm[1],
        "מס' וריאנט": itm[2],
        'שם וריאנט': itm[3],
        'שם ספק': itm[4],
        'מחיר לארגון': itm[5],
        'מחיר בסיס': itm[6],
        'ימי אספקה': itm[7],
        מקט: itm[8],

        hatava_number: itm[0],
        hatava_name: itm[1],
        variant_id: itm[2],
        variant_name: itm[3],
        sapak_name: itm[4],
        org_price: itm[5],
        base_price: itm[6],
        delivery_days: itm[7],
        makat: itm[8],
      });
    });
    console.log('tmpArray', tmpArray);
    setRows(tmpArray);

    setLoading(false);
  }, [fileData]);

  useEffect(() => {}, [fixed]);

  const fixDataBase = async () => {
    setLoading(true);
    axiosInstance
      .post('/variants/fixmissing', { rows: rows })
      .then((res) => {
        console.log(res.data);
        let fixedArr = res.data;
        console.log(Array.isArray(fixedArr));

        setFixed([...fixedArr]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <br></br>

      <div style={{ marginRight: '5vw', fontSize: '1.3rem' }}>
        תיקון וריאנטים עם שדות חסרים
      </div>

      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        <Box>
          <Paper>
            <CSVReader
              onUploadAccepted={(results) => {
                setFileData(results.data);
                // console.log(results);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
              }) => (
                <>
                  <Box px={1}>
                    <Button variant="outlined" {...getRootProps()}>
                      ייבוא וריאנטים תצוגה מקדימה
                    </Button>
                    {rows && rows.length > 0 && (
                      <Button variant="outlined" onClick={fixDataBase}>
                        השלם שדות של וריאנטים שקיימים בדטה בייס לפי קובץ זה
                      </Button>
                    )}
                    <Typography sx={{ marginRight: 1 }} variant="caption">
                      {acceptedFile && 'רשומות המכילות את השם עלמא בשם הספק'}
                    </Typography>

                    <LinearProgress
                      sx={{ margin: 2 }}
                      variant="determinate"
                      value={ProgressBar.value || 0}
                    />
                  </Box>
                </>
              )}
            </CSVReader>
          </Paper>
        </Box>

        {rows && columns && rows.length > 0 && (
          <DataGrid
            autoHeight //!!!FUCKIN IMPORTATN
            components={{
              Toolbar: GridToolbar,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  טוען רשומות
                </Stack>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  אין תוצאות
                </Stack>
              ),
            }}
            cell--editable
            pagination
            sx={{
              fontSize: 13,
              '& .MuiTablePagination-actions': {
                direction: 'ltr !important',
              },
              '& .MuiDataGrid-footerContainer': {
                direction: 'ltr !important',
              },
            }}
            density="compact"
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id.toString())
              );
            }}
            {...fileData}
            // selectionModel={selectionModel}
          />
        )}
      </Container>
      {loading && (
        <div
          style={{
            marginRight: '50px',
            fontSize: '1.3rem',
            color: '#1976D2',
          }}
        >
          טוען...
        </div>
      )}

      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        {fixed.length > 0 && <h6>הושלמו השדות החסרים </h6>}
        {fixed.length > 0 && <h6> פרט לפריטים שלא נמצאים בקובץ הנוכחי </h6>}
        {fixed.length > 0 && (
          <small>
            {' '}
            פריטים שעדיין חסרים להם שדות נכון להיום: {fixed.length}{' '}
          </small>
        )}
        {fixed &&
          fixed.length > 0 &&
          fixed.map((itm, index) => (
            <div className="container">
              <div
                key={itm.variant_id}
                style={{
                  fontSize: '.8rem',
                  background: '#F6F6F6',
                  padding: '10px',
                  borderBottom: '1px solid #E0E0E0',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    background: '#f7d9d9',
                    minWidth: '70px',
                    padding: '5px',
                  }}
                >
                  {itm?.variant_id}
                </div>
                <div
                  style={{
                    background: '#f7d9d9',
                    minWidth: '312px',
                    padding: '5px',
                  }}
                >
                  {itm?.variant_name}
                </div>
                <div
                  style={{
                    background: '#f7d9d9',
                    minWidth: '50px',
                    padding: '5px',
                  }}
                >
                  {itm?.hatava_name}
                </div>
                <div
                  style={{
                    background: '#f7d9d9',
                    minWidth: '50px',
                    padding: '5px',
                  }}
                >
                  {itm?.hatava_number}
                </div>
                <div
                  style={{
                    background: '#f7d9d9',
                    minWidth: '50px',
                    padding: '5px',
                  }}
                >
                  {itm?.hovala}
                </div>
                <div
                  style={{
                    background: itm.makat === 'מקט חסר' ? 'green' : '#f7d9d9',
                    minWidth: '50px',
                    padding: '5px',
                  }}
                >
                  {itm?.makat}
                </div>
              </div>
            </div>
          ))}
      </Container>
    </div>
  );
};

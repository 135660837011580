import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';

import {
  DataGridPro as DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import {
  Container,
  Typography,
  LinearProgress,
  Stack,
  TextField,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useDebounce } from '../hooks/useDebounce';
import { columns } from './Home.utils';
import { useAuth } from '../hooks/useAuth';

export const Home = () => {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  const [textValue, setTextValue] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);

  const { userData } = useAuth();

  const debouncedValue = useDebounce(textValue);

  const titles = [];
  columns.forEach((col) => {
    if (col.field === 'cost_price') {
      if (userData.isAdmin) titles.push(col);
    } else titles.push(col);
  });
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }
    setSelection([]);

    //#### Rows #######################################################

    const tmpArray = data.map((itm, index) => {
      return {
        ...itm,
        id: itm.variant_id,
        variant_id: itm.variant_id,
        createdAt: new Date(itm.createdAt),
        updatedAt: new Date(itm.updatedAt),
        amla: itm.amla ? itm.amla.toFixed(3) : 0,
        changedManualy: itm.changedManualy === true ? 'כן' : 'לא',
        active: itm.active === true ? 'כן' : 'לא',
      };
    });

    setRows(tmpArray);
  }, [data]);

  const handleTextChange = (event) => {
    const value = event.target.value;
    setTextValue(value);
  };

  //get data by text debounced value
  const getData = async (variant_id, signal) => {
    console.log('get data function');
    setBussyImporting(true);

    try {
      const response = await axiosInstance.get(`/variants/${variant_id}`, {
        signal,
      });

      setData(response.data);

      let kobi = response?.data?.filter((x) => x.sapak_name?.includes('קובי'));

      kobi && console.log(kobi);
    } catch (error) {
      console.log(error);
    }

    setBussyImporting(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    console.log('debounced value', debouncedValue);
    if (debouncedValue.length >= 3) {
      console.log('api call');
      getData(debouncedValue, controller.signal);
    }
    return () => controller.abort();
  }, [debouncedValue]);

  //send emsil to sapakim
  const handleSendEmail = async () => {
    if (!selection || !selection.length > 0) {
      setSystemMsg('לא נבחרו רשומות');
      Swal.fire({
        title: 'נא לבחור רשומות',
        text: 'לא נבחרו רשומות',
        icon: 'error',
        confirmButtonText: 'אישור',
      });
      return;
    }
    setSystemMsg('  שליחת דוחות לספקים');

    //get unique sapak_id from selection
    console.log('selection.length = ' + selection.length);

    //send selection array to server
    const result = await axiosInstance.post('/sapakim/send_spapk_report_main', {
      selection,
    });
    console.log(result);
    setSystemMsg('הדוח נשלח בהצלחה');
    setTimeout(() => {
      setSystemMsg('');
    }, 1200);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ marginTop: 3 }}>
      <HomeHeaders textValue={textValue} handleTextChange={handleTextChange} />

      <Button
        sx={{ marginBottom: 1 }}
        onClick={handleSendEmail}
        disableElevation
        variant="outlined"
        color="secondary"
      >
        {!bussyImporting ? 'שליחת דוח לספקים' : '...אנא המתן טוען'}
      </Button>
      <Typography marginRight={2} variant="caption" color="blue">
        {systemMsg}
      </Typography>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          loading={bussyImporting}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                אין תוצאות
              </Stack>
            ),
          }}
          pagination
          sx={{
            fontSize: 13,
            '& .MuiTablePagination-actions': {
              direction: 'ltr !important',
            },
            '& .MuiDataGrid-footerContainer': {
              direction: 'ltr !important',
            },
          }}
          density="compact"
          rows={rows}
          columns={titles}
          pageSize={14}
          rowsPerPageOptions={[14]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            console.log(selectedRowData.length);
            if (selectedRowData.length > 0) {
              setSystemMsg(selectedRowData.length + ' רשומות נבחרו');
            } else {
              setSystemMsg('לא נבחרו רשומות');
            }
            setSelectionModel(ids);
            setSelection([...selectedRowData]);
          }}
          {...data}
          selectionModel={selectionModel}
        />
      </div>
    </Container>
  );
};

function HomeHeaders({ textValue, handleTextChange }) {
  return (
    <>
      <Typography variant="h4" color="initial">
        עלמא סחר
      </Typography>

      <Typography variant="h6" color="initial">
        מערכת לניהול מוצרים
      </Typography>
      <TextField
        id="outlined-basic"
        label="אנא הכנס מספר וריאנט"
        variant="outlined"
        value={textValue}
        onChange={handleTextChange}
      />
      <hr />
      <Typography variant="body2" color="initial">
        דוח מאסטר ספק
      </Typography>
    </>
  );
}

export default Home;

import axiosInstance from './apiBaseUrl';

const defaultTime = 10000;
const ids = new Map();
let runningFunctions = [];
const listeners = new Map();
let intervalId,
  anyListener,
  stopIntervalCue = true;

const setReq = (name, axiosCall) =>
  new Promise((res, rej) => {
    if (anyListener) anyListener(true);
    axiosCall.then((response) => {
      const { status, id } = response.data;
      let e;
      if (status == 'run') e = 'Function is already running on the server';
      else if (status == 'err') e = 'Internal error on server';
      if (e) {
        console.error(e);
        rej(e);
      }
      ids.set(id, { res, rej, wait: 1, details: { name, id }, response });
      console.log('request was made: id=' + id + ' name=' + name);
    });
  });

const checkReqs = async () => {
  const list = Array.from(ids.values())
    .filter((obj) => {
      if (!obj.wait) return true;
      obj.wait = 0;
    })
    .map(({ details }) => details);
  if (!list.length) return;
  const results = await axiosInstance.post('/variants/getFunctionStatus/', {
    list,
  });
  for (let { id, status, obj } of results.data) {
    if (status == 'run') continue;
    if (status == 'err') console.error('error');
    const { res, rej, response } = ids.get(id);
    ids.delete(id);
    if (status != 'done') continue;
    response.data = obj.err || obj.res;
    ids.delete(id);
    console.log('request was done: id=' + id);
    (obj.err ? rej : res)(response);
  }
  if (anyListener) anyListener(ids.length);
};

const listen = (fName, func, opt = {}) => {
  if (listeners.has(fName) && !opt.replace) return;
  listeners.set(fName, func);
  if (opt.runFirst) func(runningFunctions.includes(fName));
};

const listenAny = (func, opt = {}) => {
  if (anyListener && !opt.replace) return;
  anyListener = func;
  if (opt.runFirst) func(runningFunctions.length);
};

const checkAllFunctions = async () => {
  if (!listeners.size) return;
  let current = await axiosInstance.get('/variants/getRunningFunctions/');
  current = current.data;
  const nin = current.filter(
    (fn) => listeners.has(fn) && !runningFunctions.includes(fn)
  );
  const nout = runningFunctions.filter(
    (fn) => listeners.has(fn) && !current.includes(fn)
  );
  nin.forEach((fn) => listeners.get(fn)(true));
  nout.forEach((fn) => listeners.get(fn)(false));
  if (anyListener && !current.length) anyListener(false);
  runningFunctions = current;
};

function makeTimeout(t) {
  return new Promise((res) => {
    setTimeout(() => res(true), t);
  });
}

async function makeInterval(func, t) {
  if (!stopIntervalCue) return;
  stopIntervalCue = false;
  while (!stopIntervalCue) {
    await func();
    await makeTimeout(t);
  }
}

function init(t) {
  t = t || defaultTime;
  makeInterval(async () => {
    await checkReqs();
    await checkAllFunctions();
  }, t);
}

function stop() {
  if (stopIntervalCue) return;
  stopIntervalCue = true;
}

export { setReq, listen, listenAny, init, stop };

import { Box, Button, IconButton, Popover, Typography } from '@mui/material';
import React, { useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export const SettingsButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    logout();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button sx={{ p: 2 }} onClick={handleClose}>
            <NavLink to="/settings">שנה סיסמא</NavLink>
          </Button>
          <Button sx={{ p: 2 }} onClick={handleLogout}>
            התנתק
          </Button>
        </Box>
      </Popover>
    </>
  );
};

import React, { useState, useEffect, useMemo } from 'react';

import {
  DataGridPro as DataGrid,
  GridToolbar,
  heIL,
} from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import {
  Container,
  Typography,
  LinearProgress,
  Stack,
  Checkbox,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

export const NonActiveVariants = () => {
  const [data, setData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [doByNewest, setDoByNewest] = useState(false);

  //data to datagrid
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }

    console.log('FX build grid ', data);
    setSelection([]);
    setBussyImporting(true);
    let thecolumns = [];

    //Columns
    const theHeadersHEB = [
      'מס וריאנט',
      'שם וריאנט',
      'מחיר עלות',
      'מחיר לארגון',
      'מס דף הטבה',
      'שם דף הטבה',
      'שם ספק',
      'ימי אספקה',
      'מקט',
      'הובלה',
      'תאריך',
    ];

    let theHeadersKeysENG = [
      'variant_id',
      'variant_name',
      'cost_price',
      'org_price',
      'hatava_number',
      'hatava_name',
      'sapak_name',
      'delivery_days',
      'makat',
      'hovala',
      'createdAt',
    ];
    // console.log(theHeadersKeysENG)
    let theHeadersKeys = [];
    theHeadersKeysENG.map((x) => {
      console.log(x);
      if (x === 'active' || x === '_id' || x === 'updatedAt') {
        return;
      } else {
        theHeadersKeys.push(x);
      }
    });

    console.log(theHeadersKeys);

    theHeadersKeys.map((itm, index) => {
      let tmpObj = {
        field: itm,
        headerName: theHeadersHEB[index],
        width: 120,
        editable: false,
        sortable: true,
        filterable: true,

        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };

      if ((index === 2) | (index === 3) | (index === 7) | (index === 8)) {
        tmpObj.type = 'number';
        tmpObj.sortable = true;
        tmpObj.width = 80;
      }
      if (index === 10) {
        tmpObj.type = 'date';
        tmpObj.sortable = true;
        tmpObj.width = 100;
      }
      if ((index === 9) | (index === 2)) {
        tmpObj.sortable = true;
      }
      if (index === 1) {
        tmpObj.width = 300;
      }
      if (index === 0) {
        tmpObj.width = 100;
      }
      if (index === 4 || index === 5 || index === 6) {
        tmpObj.width = 80;
      }
      if (index === 8) {
        tmpObj.width = 50;
      }

      thecolumns.push({ ...tmpObj });
    });
    setcolumns(thecolumns);

    //#### Rows #######################################################

    let tmpArray = [];
    data.map((itm, index) => {
      let row = {};
      // theHeadersKeys.map((header, index) => {

      row = itm;
      row = {
        ...row,
        id: itm.variant_id,
        variant_id: itm.variant_id,
        createdAt: new Date(itm.createdAt),
      };

      tmpArray.push(row);
    });

    setRows(tmpArray);
    setBussyImporting(false);
  }, [reloadall, data]);

  //get all data
  const getData = async () => {
    console.log('get data function');
    try {
      const response = await axiosInstance.post(
        '/variants/get_non_active_variants',
        { doByNewest: doByNewest }
      );

      setData(response.data);
      //  console.log(response.data)
    } catch (error) {
      console.log(error);
    }
  };
  //get data
  const dtheData = useMemo(() => {
    console.log('get data useMemo');
    setBussyImporting(true);
    getData();
  }, [doByNewest]);

  //const createPDF = () =>{
  // const doc = new jsPDF();
  // let pdfData = []

  // await Promise.all(selection.map(async(row,index) => {
  //    console.log(row.variant_id)
  //     //pdfData.push(row)
  //     doc.text(row.variant_id, 10, index*10);
  // })
  // )

  // const blob = doc.output('blob');
  // return blob
  // //usage:
  // var formData = new FormData();
  //     formData.append('file', blob);
  // const result = await axios({
  //     method: 'post',
  //     url: 'http://localhost:5000/api/sapakim/sendreports',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     data: formData
  // });
  //}

  //send emsil to sapakim
  const handleSendEmail = async () => {
    if (!selection || !selection.length > 0) {
      setSystemMsg('לא נבחרו רשומות');
      Swal.fire({
        title: 'נא לבחור רשומות',
        text: 'לא נבחרו רשומות',
        icon: 'error',
        confirmButtonText: 'אישור',
      });
      return;
    }

    //get unique sapak_id from selection
    console.log('selection.length = ' + selection.length);
    let uniqueSapakim = [];
    selection.map((x) => {
      if (!uniqueSapakim.includes(x.sapak_name)) {
        uniqueSapakim.push(
          x.sapak_name.replace('עלמא סחר', '').replace(' ', '')
        );
      }
    });
    let uniqueSapakimNames = [...new Set(uniqueSapakim)];
    console.log(uniqueSapakimNames);
    // console.log(selection)
    //split selection array by sapak_name
    let sapakimArray = [];
    uniqueSapakimNames.map((x) => {
      let tmp = selection.filter((y) => y.sapak_name.includes(x));
      // console.log(tmp)
      sapakimArray.push(tmp);
    });
    //return  console.log(sapakimArray)

    console.log('sapakimArray.length = ' + sapakimArray.length);
    //send selection array to server
    const result = await axiosInstance.post('/sapakim/send_spapk_report_main', {
      sapakimArray: sapakimArray,
      uniqueSapakimNames: uniqueSapakimNames,
    });
    console.log(result);
    setSystemMsg('הדוח נשלח בהצלחה');
    setTimeout(() => {
      setSystemMsg('');
    }, 1200);
  };
  //delete data from db
  const deleteData = (id) => {
    axiosInstance
      .delete(`/api/deleteData/${id}`)
      .then((res) => {
        setReloadall(!reloadall);
        setSystemMsg(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //import data from csv
  const importData = () => {
    setBussyImporting(true);
    axiosInstance
      .post('/api/importData')
      .then((res) => {
        setBussyImporting(false);
        setReloadall(!reloadall);
        setSystemMsg(res.data.msg);
      })
      .catch((err) => {
        setBussyImporting(false);
        console.log(err);
      });
  };

  //export data to csv
  const exportData = () => {
    axiosInstance
      .get('/api/exportData')
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        <HomeHeaders />
        <FormGroup>
          <FormControlLabel
            onClick={() => {
              setDoByNewest(!doByNewest);
              setReloadall(!reloadall);
            }}
            control={<Checkbox />}
            label="במקרה של כפילות הצג רק וריאנט אחרון"
          />
        </FormGroup>
        {/* <Button
                    sx={{ marginBottom: 1 }}
                    onClick={handleSendEmail}
                    disableElevation variant="outlined" color="secondary">

                    {!bussyImporting ?
                        "שליחת דוח לספקים"
                        :
                        "...אנא המתן טוען"
                    }
                </Button> */}
        <Typography marginRight={2} variant="caption" color="blue">
          {systemMsg}
        </Typography>
        <div style={{ height: 700, width: '100%' }}>
          <DataGrid
            components={{
              LoadingOverlay: LinearProgress,

              Toolbar: GridToolbar,

              NoRowsOverlay: () => (
                <>
                  {/* <Stack height="100%" alignItems="center" justifyContent="center">
                                    טוען רשומות...

                                </Stack>  */}
                  <LinearProgress />
                  טוען רשומות...
                </>
              ),
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  אין תוצאות
                </Stack>
              ),
            }}
            sx={{ fontSize: 13 }}
            density="compact"
            rows={rows}
            columns={columns}
            pageSize={16}
            rowsPerPageOptions={[16]}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id.toString())
              );
              console.log(selectedRowData.length);
              setSelectionModel(ids);
              setSelection([...selectedRowData]);
            }}
            {...data}
            selectionModel={selectionModel}
          />
        </div>
      </Container>
    </ThemeProvider>
  );
};

function HomeHeaders({}) {
  return (
    <>
      <Typography variant="h4" color="initial">
        {' '}
        עלמא סחר
      </Typography>

      <Typography variant="h6" color="initial">
        {' '}
        דוח וריאנטים לא פעילים
      </Typography>

      <hr />
      <Typography variant="body2" color="initial">
        דוח מאסטר ספק
      </Typography>
    </>
  );
}

import React, { useState } from 'react';
import { Button, Container, Box, Paper, styled } from '@mui/material';
import ImportLiatPrev from '../components/Imports/ImportLiatPrev';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { read, utils } from 'xlsx';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ImportLiat = () => {
  const [dataFromFile, setDataFromFile] = useState([]);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const SheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[SheetName];
      const parsedData = utils.sheet_to_json(sheet);

      setDataFromFile(parsedData);
    };
  };
  return (
    <Container maxWidth="xl" sx={{ marginTop: 3 }}>
      <Box>
        <Paper>
          <Box px={1}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<CloudUploadIcon />}
            >
              ייבוא מכירות חודשי
              <VisuallyHiddenInput
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </Button>
          </Box>
        </Paper>
      </Box>

      {dataFromFile.length > 0 && <ImportLiatPrev data={dataFromFile} />}
    </Container>
  );
};

import React, { useEffect, useState } from 'react';
import axiosInstance from '../Utils/apiBaseUrl';

import { useNavigate } from 'react-router-dom';

export const Reports = () => {
  const navigate = useNavigate();

  const [dateFrom, setDateFrom] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  );
  const [dateTo, setDateTo] = useState(
    new Date(new Date().setMonth(new Date().getMonth() + 1))
  );
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    //get all possible packs
    const getData = async () => {
      try {
        const response = await axiosInstance.post('/reports/', {
          dateTo: dateTo,
          dateFrom: dateFrom,
        });
        console.log(response.data);
        setReportData(response.data);
      } catch (error) {}
    };

    // getData()

    return () => {};
  }, []);

  useEffect(() => {
    // console.log('reportData ', reportData)
  }, [reportData]);

  return <div>REPORTS</div>;
};

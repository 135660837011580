const keysMap = {
  variant_id: 'מס וריאנט',
  variant_name: 'שם וריאנט',
  cost_price: 'מחיר עלות',
  org_price: 'מחיר לארגון',
  hatava_number: 'מס דף הטבה',
  hatava_name: 'שם דף הטבה',
  sapak_name: 'שם ספק',
  createdAt: 'תאריך יצירה',
  delivery_days: 'ימי משלוח',
  hovala: 'הובלה',
  makat: 'מקט',
  amla: 'עמלה',
  changedManualy: 'שינוי ידני',
  active: 'האם פעיל',
};

export const columns = [
  {
    field: 'variant_id',
    headerName: 'מס וריאנט',
    width: 100,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'variant_name',
    headerName: 'שם וריאנט',
    width: 180,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'variant_query',
    headerName: 'שאילתה לגוגל',
    width: 180,
    editable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'cost_price',
    headerName: 'מחיר עלות',
    width: 80,
    type: 'number',
    editable: true,
    sortable: true,
    filterable: true,
    cellClassName: 'custom-cell-editble strong',
  },
  {
    field: 'org_price',
    headerName: 'מחיר לארגון',
    width: 80,
    type: 'number',
    editable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'hatava_number',
    headerName: 'מס דף הטבה',
    width: 80,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'hatava_name',
    headerName: 'שם דף הטבה',
    width: 130,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'sapak_name',
    headerName: 'שם ספק',
    width: 200,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'createdAt',
    headerName: 'תאריך יצירה',
    width: 100,
    type: 'date',
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'delivery_days',
    headerName: 'ימי אספקה',
    width: 70,
    type: 'number',
    editable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'hovala',
    headerName: 'הובלה',
    width: 70,
    editable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'makat',
    headerName: 'מקט',
    width: 80,
    editable: true,
    sortable: true,
    filterable: true,
  },
  {
    field: 'alma',
    headerName: 'עמלה',
    width: 100,
    type: 'date',
    hide: true,
    editable: false,
    sortable: true,
    filterable: true,
  },
  {
    field: 'changedManualy',
    headerName: 'שונה מחיר ידנית',
    width: 100,
    hide: true,
    editable: false,
    sortable: true,
    filterable: true,
    cellClassName: 'custom-cell-editble border font-weight-bold',
  },
  {
    field: 'active',
    headerName: 'האם פעיל',
    width: 100,
    editable: false,
    sortable: true,
    filterable: true,
    cellClassName: 'custom-cell-editble border font-weight-bold',
  },
];

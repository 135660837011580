import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { DataGridPro as DataGrid, heIL } from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import {
  Container,
  Typography,
  TextField,
  Grid,
  LinearProgress,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
//Skeleton
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

export const Actions = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [kefekNumber, setKefekNumber] = useState(0);

  //get data
  useEffect(() => {
    getData();
  }, [reloadall]);

  //data to datagrid
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }
    //console.log('FX build grid')
    setSelection([]);
    setBussyImporting(true);
    let thecolumns = [];

    //Columns
    const theHeadersHEB = [
      'שם ספק',
      'מחיר לארגון',
      'מחיר בסיס',
      'מס וריאנט',
      'שם וריאנט',
    ];

    let theHeadersKeysENG = [
      'sapak_name',
      'org_price',
      'base_price',
      'variant_id',
      'variant_name',
    ];
    // console.log(theHeadersKeysENG)
    let theHeadersKeys = [];
    theHeadersKeysENG.map((x) => {
      // console.log(x)
      if (
        x === 'active' ||
        x === '_id' ||
        x === 'createdAt' ||
        x === 'updatedAt'
      ) {
        return;
      } else {
        theHeadersKeys.push(x);
      }
    });

    //  console.log(theHeadersKeys)

    theHeadersKeys.map((itm, index) => {
      let tmpObj = {
        field: itm,
        headerName: theHeadersHEB[index],
        width: 100,
        editable: false,
        sortable: false,
        filterable: true,

        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };

      if (index === 0) {
        tmpObj.width = 170;
      }
      if (index === 4) {
        tmpObj.width = 300;
      }

      thecolumns.push({ ...tmpObj });
    });
    setcolumns(thecolumns);

    //Rows

    let tmpArray = [];
    data.map((itm, index) => {
      let row = {};
      theHeadersKeys.map((header, index) => {
        row = itm;
        row = { ...row, id: itm.variant_id, variant_id: itm.variant_id };
      });
      //console.log(row)
      tmpArray.push(row);
    });

    setRows(tmpArray);

    setBussyImporting(false);
  }, [reloadall, data]);

  //get all data
  const getData = async () => {
    //console.log('get data function')
    try {
      const response = await axiosInstance.get('/variants/get_all_sapakim');

      setData(response.data);
      //  console.log(response.data)
    } catch (error) {
      console.log(error);
    }
  };

  //delete data from db
  const deleteData = (id) => {
    axiosInstance
      .delete(`/api/deleteData/${id}`)
      .then((res) => {
        setReloadall(!reloadall);
        setSystemMsg(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //import data from csv
  const importData = () => {
    setBussyImporting(true);
    axiosInstance
      .post('/api/importData')
      .then((res) => {
        setBussyImporting(false);
        setReloadall(!reloadall);
        setSystemMsg(res.data.msg);
      })
      .catch((err) => {
        setBussyImporting(false);
        console.log(err);
      });
  };

  //export data to csv
  const exportData = () => {
    axiosInstance
      .get('/api/exportData')
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleKefel = () => {
    if (!selection || selection.length === 0) {
      Swal.fire('לא נבחרו רשומות');
      return;
    }
    if (kefekNumber <= 0) {
      Swal.fire('הכנס מספר להכפלה');
      return;
    }
    Swal.fire({
      title: 'האם אתה בטוח?',
      text: ` מחיר בסיס יעודכן ב ${selection.length} רשומות`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'כן,עדכן',
      cancelButtonText: 'לא, בטל',
    }).then((result) => {
      if (result.value) {
        //
        // console.log(selection)
        //post on update_variants_base_price
        axiosInstance
          .post('/variants/update_variants_base_price/', {
            selection: selection,
            kefelNumber: kefekNumber,
          })
          .then((res) => {
            console.log(res);

            setReloadall(!reloadall);
            setSystemMsg('עדכון בוצע בהצלחה');
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography variant="h4" color="initial">
          {' '}
          עלמא סחר
        </Typography>
        <Typography variant="body1" color="initial">
          עדכון מחיר לפי ספק
        </Typography>
        <Typography variant="body1" color="initial">
          בחר ספקים מהרשימה והזן מספר להכפלה
        </Typography>
        <Typography variant="caption" color="initial">
          מחיר בסיס חדש =
        </Typography>
        <Typography variant="caption" color="initial">
          מחיר לארגון * {kefekNumber || 'מספר'}
        </Typography>
        <Grid
          sx={{ my: 1, padding: 0 }}
          justifyContent="flex-start"
          container
          spacing={2}
        >
          <Grid item xs={2}>
            <TextField
              required
              size="small"
              id="kefekNumber"
              type="number"
              // label="0.00"

              step=".01"
              variant="outlined"
              value={kefekNumber}
              inputProps={{
                maxLength: 5,
                min: 0,
              }}
              onChange={(e) => setKefekNumber(e.target.value)}
            />
          </Grid>

          {bussyImporting && <LinearProgress sx={{ my: 1 }} />}
          <Grid item xs={2}>
            <Button onClick={handleKefel} variant="contained" color="primary">
              החל
            </Button>
          </Grid>
        </Grid>

        {selection && selection.length > 0 ? (
          <Typography variant="body1" color="#1976d2">
            {selection.length} רשומות נבחרו
          </Typography>
        ) : (
          <>
            <Typography variant="body1" color="primary">
              0 רשומות נבחרו
            </Typography>
          </>
        )}

        {!data || data.length <= 0 ? (
          <SkeletonTheme baseColor="#e8e8e8" highlightColor="#f7f7f7">
            <p>
              <Skeleton count={5} />
              <br />
              <Skeleton count={5} />
              <br />
              <Skeleton count={5} />
            </p>
          </SkeletonTheme>
        ) : (
          <div style={{ height: 650, width: '100%' }}>
            <DataGrid
              id="datagrdi1"
              pagination
              sx={{
                fontSize: 13,
                '& .MuiTablePagination-actions': {
                  direction: 'ltr !important',
                },
                '& .MuiDataGrid-footerContainer': {
                  direction: 'ltr !important',
                },
              }}
              density="compact"
              rows={rows}
              columns={columns}
              pageSize={14}
              rowsPerPageOptions={[22]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRowData = rows.filter((row) =>
                  selectedIDs.has(row.id.toString())
                );
                // console.log(selectedRowData)
                setSelectionModel(ids);
                setSelection([...selectedRowData]);
              }}
              {...data}
              selectionModel={selectionModel}
            />
          </div>
        )}
      </Container>
    </ThemeProvider>
  );
};

var axios = require('axios');
const development = process.env.NODE_ENV !== 'production';

const axiosInstance = axios.create({
  baseURL: development
    ? 'http://localhost:5001/api'
    : 'https://almatradeltd.com/api',

  headers: {
    'Content-Type': 'application/json',
  },
});

module.exports = axiosInstance;

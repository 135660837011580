import React, { useState, useEffect } from 'react';

import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import * as longRequests from '../Utils/longRequests.js';
import {
  Container,
  Typography,
  LinearProgress,
  Stack,
  Alert,
  TextField,
} from '@mui/material';
import Swal from 'sweetalert2';
import { useDebounce } from '../hooks/useDebounce';
import { columns } from './ChangeBase.utils.js';

longRequests.init();

export const ChangeBase = () => {
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [alertMsg, setAlertMsg] = useState('');
  const [textValue, setTextValue] = useState('');
  const [showAlert, setshowAlert] = useState(false);

  const debouncedValue = useDebounce(textValue);
  //data to datagrid
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }

    setSelection([]);
    setBussyImporting(true);

    //#### Rows #######################################################

    let tmpArray = [];
    data.map((itm, index) => {
      if (itm.changedManualy === undefined || itm.changedManualy === null) {
        itm.changedManualy = 'false';
      }

      let row = {};
      // theHeadersKeys.map((header, index) => {

      row = itm;
      row = {
        ...row,
        id: itm.variant_id,
        variant_id: itm.variant_id,
        createdAt: new Date(itm.createdAt),
        amla: itm.amla ? itm.amla.toFixed(3) : 0,
        changedManualy: itm.changedManualy
          ?.toString()
          .replace('true', '✔️')
          .replace('false', '❌'),
        active: itm.active
          ?.toString()
          .replace('true', 'כן')
          .replace('false', 'לא'),
      };

      // })
      //console.log(row)
      tmpArray.push(row);
    });

    setRows(tmpArray);
    setBussyImporting(false);
  }, [reloadall, data]);

  //get data by text debounced value
  const getData = async (variant_id, signal) => {
    console.log('get data function');
    setBussyImporting(true);

    try {
      const response = await axiosInstance.get(`/variants/${variant_id}`, {
        signal,
      });

      console.log('response', response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }

    setBussyImporting(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    console.log('debounced value', debouncedValue);
    if (debouncedValue.length >= 3) {
      console.log('api call');
      getData(debouncedValue, controller.signal);
    }
    return () => controller.abort();
  }, [debouncedValue]);

  const handleTextChange = (event) => {
    console.log('val', event.target.value);
    const value = event.target.value;
    setTextValue(value);
  };

  const handleCellEdit = async (e) => {
    const fieldChanged = e.field;
    console.log('fieldChanged ', fieldChanged);
    const curVariantId = e.row.variant_id;

    const newObj = {
      [fieldChanged]: e.value,
    };

    console.log(
      curVariantId +
        ' => ' +
        'changed field ' +
        fieldChanged +
        'with value ' +
        JSON.stringify(newObj)
    );
    try {
      const response = await axiosInstance.put(`/variants/${curVariantId}`, {
        newObj: JSON.stringify(newObj),
      });

      console.log(response);
      if (response.status === 200 && response.data) {
        setAlertMsg('וריאנט עודכן!');
        setshowAlert(true);
        setTimeout(() => {
          setAlertMsg(' ');
          setshowAlert(false);
        }, 1400);
      } else {
        Swal.fire('שגיאה');
      }
    } catch (error) {
      console.log(error);
      Swal.fire('שגיאה');
    }
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: 3 }}>
      {showAlert && (
        <Alert
          style={{ position: 'absolute', top: '20', right: '20', width: 500 }}
          variant="filled"
          severity="success"
        >
          {alertMsg}
        </Alert>
      )}
      <h2>שינוי מחיר עלות </h2>

      <TextField
        id="outlined-basic"
        label="אנא הכנס מספר וריאנט"
        variant="outlined"
        value={textValue}
        onChange={handleTextChange}
      />
      <Typography marginRight={2} variant="caption" color="blue">
        {systemMsg}
      </Typography>
      <div style={{ height: 650, width: '100%' }}>
        <DataGrid
          onCellEditCommit={handleCellEdit}
          loading={bussyImporting}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: GridToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                אין תוצאות
              </Stack>
            ),
          }}
          pagination
          sx={{
            fontSize: 13,
            '& .MuiTablePagination-actions': {
              direction: 'ltr !important',
            },
            '& .MuiDataGrid-footerContainer': {
              direction: 'ltr !important',
            },
          }}
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={14}
          rowsPerPageOptions={[14]}
          checkboxSelection
          disableSelectionOnClick
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRowData = rows.filter((row) =>
              selectedIDs.has(row.id.toString())
            );
            console.log(selectedRowData.length);
            if (selectedRowData.length > 0) {
              setSystemMsg(selectedRowData.length + ' רשומות נבחרו');
            } else {
              setSystemMsg('לא נבחרו רשומות');
            }
            setSelectionModel(ids);
            setSelection([...selectedRowData]);
          }}
          {...data}
          selectionModel={selectionModel}
        />
      </div>
    </Container>
  );
};

import React, { useState, useEffect } from 'react';
import { Paper, Box, Typography, Button, styled } from '@mui/material';
import ImportVeriantsPreview from '../components/Imports/ImportVeriantsPreview';
import { read, utils } from 'xlsx';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const Imports = () => {
  const [dataFromFile, setDataFromFile] = useState([]);
  const [dataFromFileAlmaOnly, setDataFromFileAlmaOnly] = useState([]);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    console.log('isDelta', checked);
    setDataFromFile([]);
  }, [checked]);

  useEffect(() => {
    if (!dataFromFile || dataFromFile.length === 0) return;
    let tmpArr = [];
    Object.values(dataFromFile).forEach((value) => {
      const alma = value['שם ספק'].toString().includes('עלמא');
      if (alma) {
        tmpArr.push({
          'מס דף הטבה': checked ? value[`מס דף הטבה`] : value[`מס' דף הטבה`],
          'שם דף הטבה': value['שם דף הטבה'],
          'מס וריאנט': checked ? value[`מס וריאנט`] : value[`מס' וריאנט`],
          'שם וריאנט': value['שם וריאנט'],
          'שם ספק': value['שם ספק'],
          'מחיר לארגון': value['מחיר לארגון'],
          'מחיר בסיס': value['מחיר בסיס'],
          'מחיר עלות': value['מחיר עלות'],
          'ימי אספקה': value['ימי אספקה'],
          מקט: value['מקט'],
        });
      }
    });

    setDataFromFileAlmaOnly(tmpArr);
  }, [dataFromFile]);

  const handleFileUpload = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: 'binary' });
      const SheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[SheetName];
      const parsedData = utils.sheet_to_json(sheet);

      setDataFromFile(parsedData);
    };
  };
  return (
    <>
      <div className="container-big">
        <Box>
          <Paper>
            <label>
              <input
                style={{ width: '25px', height: '25px' }}
                type="checkbox"
                defaultChecked={checked}
                onChange={() => setChecked(!checked)}
              />

              {checked ? (
                <span style={{ fontSize: '2.2rem' }}> דלתא </span>
              ) : (
                <span style={{ fontSize: '2.2rem' }}> גולמי </span>
              )}
            </label>
            <Box px={1}>
              {checked ? (
                <Button
                  variant="outlined"
                  component="label"
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: '400',
                    color: 'white',
                    background: 'blue',
                  }}
                >
                  דלתא ייבוא וריאנטים תצוגה מקדימה
                  <VisuallyHiddenInput
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                  />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  component="label"
                  style={{
                    fontSize: '1.2rem',
                    fontWeight: '400',
                    color: 'blue',
                    background: 'white',
                  }}
                >
                  גולמי ייבוא וריאנטים תצוגה מקדימה
                  <VisuallyHiddenInput
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                  />
                </Button>
              )}
              <Typography sx={{ marginRight: 1 }} variant="caption">
                {dataFromFile.length > 0 &&
                  'רשומות המכילות את השם עלמא בשם הספק'}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </div>

      {dataFromFile.length > 0 && (
        <ImportVeriantsPreview data={dataFromFileAlmaOnly} checked={checked} />
      )}
    </>
  );
};

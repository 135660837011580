import React, { createContext, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import axiosInstance from '../Utils/apiBaseUrl';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userData, setUserData] = useState();

  const queryFn = async () => {
    const response = await axiosInstance.post('/user/validate', { token });
    setIsAuthenticated(true);
    setUserData(response.data.user);
    axiosInstance.defaults.headers.common['authorization'] = `Bearer ${token}`;
  };

  const { isLoading } = useQuery({
    queryKey: ['auth', token],
    queryFn,
    enabled: Boolean(token),
    staleTime: Infinity,
  });

  const login = async (email, password) => {
    const { data } = await axiosInstance.post('/user/login', {
      email,
      password,
    });

    const { user } = data;
    setUserData(user);
  };

  const verifyAuthCode = async (email, code) => {
    const { data } = await axiosInstance.post('/user/verify', {
      email,
      code,
    });

    const { token } = data;
    localStorage.setItem('token', token);
    setToken(token);
  };

  const updateUser = async (email, password) => {
    const { data } = await axiosInstance.post('/user/update', {
      email,
      password,
    });

    const { token } = data;
    localStorage.setItem('token', token);
    setToken(token);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        userData,
        login,
        logout,
        updateUser,
        verifyAuthCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

export const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { isAuthenticated, updateUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleUpdateUser = async () => {
    if (!password || password !== confirmPassword) {
      Swal.fire({ title: 'הסיסמאות לא זהות' });
      return;
    }

    try {
      setIsLoading(true);
      await updateUser(email, password);
      Swal.fire({ title: 'הסיסמא שונתה בהצלחה' });
      setEmail('');
      setConfirmPassword('');
      setPassword('');
    } catch (error) {
      console.log(error);
      Swal.fire({ title: 'שם משתמש או סיסמא לא נכונים' });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Typography.Title>עלמא סחר</Typography.Title>
        <Typography.Title level={4}> שינוי סיסמא</Typography.Title>
      </div>
      <Form
        style={{ margin: 'auto' }}
        name="change_pass"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={handleUpdateUser}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: 'Please enter your Email!',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please enter your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="confirm-password"
          rules={[
            {
              required: true,
              message: 'Please enter your Password!',
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={isLoading}
          >
            שנה סיסמא
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

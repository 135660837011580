import React, { useState, useEffect } from 'react';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import {
  Container,
  Typography,
  LinearProgress,
  Stack,
  Alert,
  TextField,
  Checkbox,
  Button,
  Box,
  Modal,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import Swal from 'sweetalert2';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Download,
  Send as SendIcon,
} from '@mui/icons-material';
//Skeleton
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { saveAs } from 'file-saver';
const style = {
  //modal
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Sapakim = () => {
  const [data, setData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [bussyImporting, setBussyImporting] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [alertMsg, setAlertMsg] = useState('');
  const [showAlert, setshowAlert] = useState(false);
  const [newSapaak, setNewSapaak] = useState({});
  const [open, setOpen] = React.useState(false);

  //get data
  useEffect(() => {
    setBussyImporting(true);
    getData();
    setBussyImporting(false);
  }, []);

  //FX build grid
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }
    //console.log('FX build grid ', data)
    setBussyImporting(true);
    let thecolumns = [];
    //Columns
    const theHeadersHEB = [
      'קוד ספק',
      'שם ספק',
      'אימייל ספק',
      'שלח אימייל ',
      'מייל איש סחר 1',
      'מייל איש סחר 2',
      'מייל איש סחר 3',
      'מייל כספים 1',
      'מייל כספים 2',
      'מייל כספים 3',
      'תזמון מיילים',
    ];
    let theHeadersKeysENG = [
      'sapak_id',
      'sapak_name',
      'email',
      'send_email',
      'field_1',
      'field_2',
      'field_3',
      'accountingEmail_1',
      'accountingEmail_2',
      'accountingEmail_3',
      'emailsTiming',
    ];
    let theHeadersKeys = [];
    theHeadersKeysENG.map((x) => {
      //console.log(x)
      if (x === 'active' || x === '_id' || x === 'updatedAt') {
        return;
      } else {
        theHeadersKeys.push(x);
      }
    });
    //console.log(theHeadersKeys)
    theHeadersKeys.map((itm, index) => {
      let tmpObj = {
        field: itm,
        headerName: theHeadersHEB[index],
        width: 120,
        editable: true,
        sortable: true,
        filterable: true,
        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };
      if (index === 3) {
        tmpObj.type = 'boolean';
        tmpObj.editable = true;
      }
      if (
        index === 4 ||
        index === 5 ||
        index === 6 ||
        index === 8 ||
        index === 9 ||
        index === 7
      ) {
        tmpObj.type = 'string';
        tmpObj.editable = true;
      }
      if (index === 10) {
        tmpObj.type = 'singleSelect';
        tmpObj.editable = true;
        tmpObj.valueOptions = [
          { value: 'יזום', label: 'יזום' },
          { value: 'יומי', label: 'יומי' },
          { value: 'שבועי', label: 'שבועי' },
          { value: 'חודשי', label: 'חודשי' },
        ];
      }
      thecolumns.push({ ...tmpObj });
    });
    setcolumns(thecolumns);
    //#### Rows #######################################################
    let tmpArray = [];
    let dataNoHeader = data.slice(0, data.length);
    dataNoHeader.map((itm, index) => {
      let row = {};

      row = { ...itm, id: itm.sapak_id };

      // })
      //console.log(itm)
      tmpArray.push(row);
    });
    console.log(tmpArray);
    setRows(tmpArray);
    setBussyImporting(false);
  }, [data]);

  //get all data
  const getData = async () => {
    //console.log('get data function')
    try {
      const response = await axiosInstance.get('/sapakim/');
      setData(response.data);
    } catch (error) {
      //console.log(error)
    }
  };

  const handleCellEdit = async (e) => {
    console.log(e);
    let sapak;
    if (e.field === 'emailsTiming') {
      sapak = data.filter((sapak) => sapak.sapak_id === e.id)[0];
    }

    const tmpSapakObj = {
      email: sapak ? sapak.email : e.row.email,
      sapak_id: sapak ? sapak.sapak_id : e.row.sapak_id,
      sapak_name: sapak ? sapak.sapak_name : e.row.sapak_name,
      send_email: sapak ? sapak.send_email : e.row.send_email,
      [e.field]: e.value,
    };

    console.log(tmpSapakObj);

    const response = await axiosInstance.put(`/sapakim/${e.id}`, {
      sapak: tmpSapakObj,
    });

    console.log(response);
    if (response.data.ok) {
      setAlertMsg('ספק עודכן!');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 1400);
    } else {
      Swal.fire('שגיאה');
    }
  };

  //add new sapak to grid
  const addNewSapak = async () => {
    //add new empty row to datagrid
    console.log('add new sapak to grid');
  };
  //save spapk
  const saveSapak = async () => {
    setOpen(false);

    console.log(newSapaak);

    if (!newSapaak.sapak_name || !newSapaak.email || !newSapaak.sapak_id) {
      setAlertMsg('חובה למלא את כל השדות');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
      return;
    }

    let tmpObj = { ...newSapaak, send_email: newSapaak.send_email === 'on' };
    console.log(tmpObj);
    setNewSapaak(tmpObj);
    try {
      const response = await axiosInstance.post('/sapakim/', {
        newSapaak: tmpObj,
      });
      console.log(response);
      getData();
    } catch (error) {
      console.log(error);
      setAlertMsg('  שגיאה  ');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
    }
  };

  //delete data from db
  const handleDeleteSapak = () => {
    console.log('delete sapak ', selectionModel);
    if (!selectionModel.length > 0) {
      setAlertMsg('נא לבחור לפחות ספק אחד ');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
      return;
    }

    let idsToDelete = [...selectionModel];

    axiosInstance
      .post('/sapakim/delete_sapakim', { idsToDelete: idsToDelete })
      .then((res) => {
        getData();
        setAlertMsg('נמחק בהצלחה ');
        setshowAlert(true);
        setTimeout(() => {
          setAlertMsg(' ');
          setshowAlert(false);
        }, 1400);
        return;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handle sending the finance emails and the sapak emails
  const handleSendExcels = async () => {
    console.log('handleSendExcels of the sapakim', selectionModel);
    if (!selectionModel.length > 0) {
      setAlertMsg('נא לבחור לפחות ספק אחד ');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
      return;
    }

    const sapakIds = [...selectionModel];

    try {
      const res = await axiosInstance.post('/sapakim/send_mails', {
        ids: sapakIds,
      });
      console.log('resss ', res);

      setAlertMsg('המיילים נשלחו בהצלחה');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
    } catch (err) {
      console.log(err);
    }
    setSelectionModel([]);
  };

  const handleScrapeSapakim = async () => {
    console.log('handleSendExcels of the sapakim', selectionModel);
    if (!selectionModel.length > 0) {
      setAlertMsg('נא לבחור לפחות ספק אחד ');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
      return;
    }

    const sapakIds = [...selectionModel];

    try {
      const res = await axiosInstance.post('/sapakim/scrape_variants', {
        ids: sapakIds,
      });
      console.log('resss ', res);
    } catch (err) {
      console.log(err);
    }
    setSelectionModel([]);
  };

  //handle sending the financial reports to the Sapaks
  const handleSendLiatReportsToSapaks = async (sendEmails) => {
    console.log('handleSendLiatReportsToSapaks ', selectionModel);
    if (!selectionModel.length > 0) {
      setAlertMsg('נא לבחור לפחות ספק אחד ');
      setshowAlert(true);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
      setSelectionModel([]);
      return;
    }

    const sapakIds = [...selectionModel];

    try {
      //if send emails is true, send emails to to the sapaks Ksafim
      //if not, download zip file
      let result;
      if (sendEmails) {
        const res = await axiosInstance.post('/sapakim/send_liat_report', {
          ids: sapakIds,
        });
        console.log('resss ', res);
        result = res.data.total;
      } else {
        const res = await axiosInstance.post(
          '/sapakim/send_liat_report_zip',
          {
            ids: sapakIds,
          },
          {
            responseType: 'blob',
          }
        );
        console.log('resss ', res);
        saveAs(res.data, 'liat-report.zip');
        result = sapakIds.length;
      }

      setAlertMsg(`נשלחו בהצלחה ${result} מיילים`);
      setshowAlert(true);
      setSelectionModel([]);
      setTimeout(() => {
        setAlertMsg(' ');
        setshowAlert(false);
      }, 2400);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={addNewSapak}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              onChange={(e) => {
                setNewSapaak({ ...newSapaak, sapak_id: e.target.value });
              }}
              required
              style={{ direction: 'rtl' }}
              helperText="קוד ספק"
              id="sapak_id"
              type="text"
            />

            <TextField
              onChange={(e) => {
                setNewSapaak({ ...newSapaak, sapak_name: e.target.value });
              }}
              helperText="שם ספק"
              id="sapak_name"
              type="text"
            />

            <TextField
              onChange={(e) => {
                setNewSapaak({ ...newSapaak, email: e.target.value });
              }}
              helperText="אימייל ספק"
              id="email"
              type="email"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={newSapaak.send_email}
                  onChange={(e) => {
                    setNewSapaak({ ...newSapaak, send_email: e.target.value });
                  }}
                />
              }
              label="שליחת  דוחות לאימייל  "
            />

            <Box>
              <Button
                onClick={() => setOpen(false)}
                sx={{ width: '90px', ml: 1 }}
                variant="contained"
              >
                ביטול
              </Button>

              <Button
                sx={{ width: '90px' }}
                onClick={saveSapak}
                variant="contained"
              >
                שמירה
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Modal>

      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        {showAlert && (
          <Alert
            style={{ position: 'absolute', top: '20', right: '20', width: 500 }}
            variant="filled"
            severity="success"
          >
            {alertMsg}
          </Alert>
        )}
        {bussyImporting && <LinearProgress sx={{ my: 1 }} />}
        <HomeHeaders />
        <div style={{ height: 700, width: '100%', position: 'relative' }}>
          <Button
            sx={{ mb: 1 }}
            onClick={() => setOpen(true)}
            variant="contained"
            color="primary"
          >
            הוספה <AddCircleIcon sx={{ marginRight: 2 }} />
          </Button>
          <Button
            sx={{ marginRight: 2, mb: 1 }}
            onClick={handleDeleteSapak}
            variant="contained"
            color="primary"
          >
            מחיקה <DeleteIcon sx={{ marginRight: 2 }} />
          </Button>
          <Button
            sx={{ position: 'absolute', left: 0 }}
            onClick={() => handleSendLiatReportsToSapaks(true)}
            variant="contained"
            color="primary"
          >
            שלח דוח כספים <SendIcon sx={{ marginRight: 2 }} />
          </Button>
          <Button
            sx={{ position: 'absolute', left: 200 }}
            onClick={() => handleSendLiatReportsToSapaks(false)}
            variant="contained"
            color="primary"
          >
            הורד קובץ זיפ כספים
            <Download sx={{ marginRight: 2 }} />
          </Button>
          <Button
            sx={{ position: 'absolute', left: 950 }}
            onClick={handleSendExcels}
            variant="contained"
            color="primary"
          >
            שלח קבצי אקסל של ספק וכספים
            <Download sx={{ marginRight: 2 }} />
          </Button>
          <Button
            sx={{ position: 'absolute', left: 450 }}
            onClick={handleScrapeSapakim}
            variant="contained"
            color="primary"
          >
           התחל בדיקת וריאנטים על פי ספק<Download sx={{ marginRight: 2 }} />
          </Button>

          {!data || data.length <= 0 ? (
            <SkeletonTheme baseColor="#e8e8e8" highlightColor="#f7f7f7">
              <p>
                <Skeleton count={5} />
                <br />
                <Skeleton count={5} />
                <br />
                <Skeleton count={5} />
              </p>
            </SkeletonTheme>
          ) : (
            <DataGrid
              onCellEditCommit={handleCellEdit}
              oncell
              // filterMode="server"
              // onFilterModelChange={(model, details) => {
              //   console.log('model', model);
              //   console.log('details', details);
              // }}
              // onSelectionModelChange={(ids) =>
              // {

              // }}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    טוען רשומות
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    אין תוצאות
                  </Stack>
                ),
              }}
              sx={{ fontSize: 13 }}
              density="compact"
              rows={rows}
              columns={columns}
              pageSize={16}
              rowsPerPageOptions={[16]}
              checkboxSelection
              disableSelectionOnClick
              {...data}
              selectionModel={selectionModel}
              onSelectionModelChange={(ids) => {
                const selectedRowData = [];

                console.log(ids);
                setSelectionModel(ids);
                console.log(selectedRowData);
              }}
            />
          )}
        </div>
      </Container>
    </>
  );
};
function HomeHeaders({}) {
  return (
    <>
      <Typography variant="h4" color="initial">
        עלמא סחר
      </Typography>
      <Typography variant="subtitle2" color="initial">
        ספקים - רשימת תפוצה
      </Typography>
    </>
  );
}
export default Sapakim;

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login } from '../pages/Login';
import { NotFound } from '../pages/NotFound';
import { AppLayout } from '../components/AppLayout';
import PrivateRoute from './PrivateRoute';
import {
  Home,
  Imports,
  Actions,
  Amlot,
  Sapakim,
  Reports,
  ImportLiat,
  ChangeBase,
  Fixmissing,
  Liatreport,
  NonActiveVariants,
} from '../routes';
import { Settings } from '../pages/Settings';

export default function AppRouter() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route exact path="/" element={<Home />} />
        <Route path="/imports" element={<Imports />} />
        <Route path="/importLiat" element={<ImportLiat />} />
        <Route path="/actions" element={<Actions />} />
        <Route path="/sapakim" element={<Sapakim />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/liatreport" element={<Liatreport />} />
        <Route path="/NonActiveVariants" element={<NonActiveVariants />} />
        <Route path="/Amlot" element={<Amlot />} />
        <Route path="/changeBase" element={<ChangeBase />} />
        <Route path="/fixmissing" element={<Fixmissing />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}

import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useAuth } from '../hooks/useAuth';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [enteredPass, setEnteredPass] = useState('');
  const [enteredCode, setEnteredCode] = useState('');
  const { isAuthenticated, login, userData, verifyAuthCode } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async () => {
    try {
      if (
        !email.includes('@') &&
        (!email.includes('gmail.com') || !email.includes('almatradeltd.com'))
      ) {
        Swal.fire({ title: 'שם משתמש או סיסמא לא נכונים' });
        return;
      }
      setIsLoading(true);
      await login(email, enteredPass);
    } catch (error) {
      console.log(error);
      Swal.fire({ title: 'שם משתמש או סיסמא לא נכונים' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    try {
      if (enteredCode !== '') {
        setIsLoading(true);
        await verifyAuthCode(userData.email, enteredCode);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({ title: 'קוד שגוי' });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <div style={{ margin: 'auto', textAlign: 'center' }}>
        <Typography.Title>עלמא סחר</Typography.Title>
        <Typography.Title level={5}> מערכת לניהול מוצרים</Typography.Title>
      </div>
      {!userData ? (
        <Form
          style={{ margin: 'auto' }}
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={handleLogin}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please enter your Email!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please enter your Password!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={enteredPass}
              onChange={(e) => setEnteredPass(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Form
          style={{ margin: 'auto' }}
          name="enter_code"
          className="login-form"
          initialValues={{
            remember: false,
          }}
          onFinish={handleVerifyCode}
        >
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: 'Please input your Code!',
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Please enter the access code sent to your email"
              value={enteredCode}
              onChange={(e) => setEnteredCode(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={handleVerifyCode}
              disabled={isLoading}
            >
              אשר קוד
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

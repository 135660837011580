export const defaultTitle = {
  align: 'left',
  headerAlign: 'left',
  headerClassName: 'datagrid-custom-header',
  width: 160,
  editable: false,
  sortable: false,
  filterable: true,
  //cellClassName: "custom-cell",

  filter: {
    type: 'text',
  },
};

import React, { useState, useEffect, useMemo, useRef } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import {
  DataGridPro as DataGrid,
  GridToolbar,
  heIL,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid-pro';
import axiosInstance from '../Utils/apiBaseUrl';
import {
  Container,
  Typography,
  LinearProgress,
  Stack,
  Box,
  Grid,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { LicenseInfo } from '@mui/x-license-pro';
import Swal from 'sweetalert2';
//Skeleton
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

LicenseInfo.setLicenseKey(
  '61628ce74db2c1b62783a6d438593bc5Tz1NVUktRG9jLEU9MTY4MzQ0NzgyMTI4NCxTPXByZW1pdW0sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);
const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
      secondary: { main: '#f50057' },
      error: { main: '#f44336' },
      warning: { main: '#ff9800' },
      info: { main: '#0F4360' },
      blueLight: { main: '#447FA1' },
    },
  },
  heIL
);

export const Liatreport = () => {
  const apiRef = useGridApiRef();

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [columns, setcolumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState();
  const [reloadall, setReloadall] = useState(false);
  const [bussyImporting, setBussyImporting] = useState(false);
  const [systemMsg, setSystemMsg] = useState('');
  const [selectionModel, setSelectionModel] = useState([]);
  const [filterModel, setfilterModel] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [sapakKatzePayment, setSapakKatzePayment] = useState(0);
  const [filteredIds, setFilteredids] = useState([]);

  useEffect(() => {
    const ids = gridFilteredSortedRowIdsSelector(apiRef);
    setSelectionModel(ids);
    //update selection
    const selectedIDs = new Set(ids);
    const selectedRowData = rows.filter((row) =>
      selectedIDs.has(row.id.toString())
    );
    setSelection(selectedRowData);

    return () => {};
  }, [filterModel]);

  // //fx selection
  useEffect(() => {
    // console.log('FX selection changed ', selection?.length)
    if (selection && selection.length > 0) {
      // console.log('FX selection changed ', selection.length)
      calculateSapak();
    } else {
      // console.log('FX selection changed ', selection?.length)
      setSapakKatzePayment(0);
    }
    return () => {};
  }, [selection]);

  //selection  changed calculate sapak
  const calculateSapak = () => {
    let totalSlaesTmp = 0;
    let sapakKatzePaymentTmp = 0;
    selection.map((itm, index) => {
      // console.log('itm.sapak_katze_payment', itm.sapak_katze_payment)
      sapakKatzePaymentTmp += itm.calc_result;
    });

    //setTotalSales(totalSlaesTmp)
    setSapakKatzePayment(sapakKatzePaymentTmp);
  };

  //data to datagrid
  useEffect(() => {
    if (!data || !data.length > 0) {
      return;
    }

    console.log('FX build grid ');
    setSelection([]);
    setBussyImporting(true);
    let thecolumns = [];

    //Columns
    const theHeadersHEB = [
      'ת. קבלת הדוחות',
      'תאריך הזמנה',
      'תאריך מימוש',
      'קוד לקוח',
      'שם לקוח',
      'קוד ספק',
      'שם ספק',
      'קוד מוצר',
      'שם מוצר',
      'כמות שוברים',
      'מחיר עלות',
      'סך מכירות',
      'מספר הזמנה',
      'מחיר בסיס שנלקח מהוריאנט = תשלום לספק קצה',
      'תוצאת חישוב',
    ];

    let theHeadersKeysENG = [
      'report_received_date',
      'order_date',
      'mimush_date',
      'customer_id',
      'customer_name',
      'sapak_id',
      'sapak_name',
      'product_id',
      'product_name',
      'shovarim',
      'cost_price',
      'total_sales',

      'order_number',
      'sapak_katze_payment',
      'calc_result',
    ];
    // console.log(theHeadersKeysENG)
    let theHeadersKeys = [];
    theHeadersKeysENG.map((x) => {
      // console.log(x)
      if (x === 'active' || x === '_id' || x === 'updatedAt') {
        return;
      } else {
        theHeadersKeys.push(x);
      }
    });

    // console.log(theHeadersKeys)

    theHeadersKeys.map((itm, index) => {
      let tmpObj = {
        field: itm,
        align: 'left',
        headerAlign: 'left',
        headerClassName: 'datagrid-custom-header-smaller',
        headerName: theHeadersHEB[index],
        width: 80,
        editable: false,
        sortable: true,
        filterable: true,

        filter: {
          type: 'text',
          placeholder: 'חיפוש לפי ' + itm,
        },
      };

      if ((index === 0) | (index === 1) | (index === 2)) {
        tmpObj.type = 'date';
        tmpObj.sortable = true;
        // tmpObj.width = 100
      }
      if ((index === 8) | (index === 9) | (index === 10) | (index === 11)) {
        tmpObj.type = 'number';
        tmpObj.sortable = true;
        //tmpObj.width = 100
      }
      if ((index === 11) | (index === 5) | (index === 7)) {
        //tmpObj.width = 140
      }
      if (index === 13) {
        tmpObj.width = 240;
        tmpObj.type = 'number';
      }
      if (index === 14) {
        tmpObj.cellClassName = 'custom-cell-editble border font-weight-bold';
        tmpObj.type = 'number';
      }

      thecolumns.push({ ...tmpObj });
    });
    setcolumns(thecolumns);

    //#### Rows #######################################################

    let tmpArray = [];
    let totalSlaesTmp = 0;

    data.map((itm, index) => {
      //TEST skip when 7286660itm.order_number !== 7286660
      // if (itm.order_number !== "7286660") {
      //     return
      // }

      let row = {};
      // theHeadersKeys.map((header, index) => {

      row = itm;
      row = {
        ...row,
        report_received_date: new Date(itm.report_received_date),
        id: itm.order_number,
        order_date: new Date(itm.order_date),
        //moment(itm.order_date).format('D.MM.YYYY'),
        mimush_date: new Date(itm.mimush_date),
        //moment(itm.mimush_date).format('D.MM.YYYY')
        // sapak_katze_payment: itm.base_price * itm.shovarim,
        // sapak_katze_payment: itm.base * itm.shovarim, was not good as per maayan and elad
        // sapak_katze_payment: itm.base_price * itm.shovarim,
        calc_result: itm.sapak_katze_payment * itm.shovarim,
      };
      //   console.log("itm.shovarim ", itm.shovarim)
      //   console.log("base_price", itm.base_price )
      //   console.log("sapak_katze_payment ", itm.base_price * itm.shovarim)
      // totalSlaesTmp += (itm.base_price * itm.shovarim)
      // spakKatzePaymentTmp += itm.sapak_katze_payment

      // })
      //console.log(row)
      tmpArray.push(row);
      // setTotalSales(totalSlaesTmp)
      //setSapakKatzePayment(spakKatzePaymentTmp)
    });

    setRows(tmpArray);
    setBussyImporting(false);
  }, [reloadall, data]);

  //get all data
  const getData = async () => {
    console.log('get data function');
    try {
      const response = await axiosInstance.get('/liat');
      console.log('get response', response);
      if (response.status === 200) {
        setData(response.data); //.slice(0, 5000)
      }
      // console.log(response.data)
    } catch (error) {
      console.log(error);
    }
  };
  //get data
  const dtheData = useMemo(() => {
    setBussyImporting(true);
    getData();
    setBussyImporting(false);
  }, []);

  //send emsil to sapakim
  const handleSendEmail = async () => {
    if (!selection || !selection.length > 0) {
      setSystemMsg('לא נבחרו רשומות');
      Swal.fire({
        title: 'נא לבחור רשומות',
        text: 'לא נבחרו רשומות',
        icon: 'error',
        confirmButtonText: 'אישור',
      });
      return;
    }

    // return  console.log(selection)
    let dataToSend = [...selection];
    console.log('sending data ', dataToSend);
    //send selection array to server
    const result = await axiosInstance.post('/sapakim/send_spapk_report_liat', {
      data: dataToSend,
    });
    console.log(result.data);
    setSystemMsg(result.data);
    setTimeout(() => {
      setSystemMsg('');
    }, 1200);
  };
  //delete data from db
  const deleteData = (id) => {
    axiosInstance
      .delete(`/api/deleteData/${id}`)
      .then((res) => {
        setReloadall(!reloadall);
        setSystemMsg(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //import data from csv
  const importData = () => {
    setBussyImporting(true);
    axiosInstance
      .post('/api/importData')
      .then((res) => {
        setBussyImporting(false);
        setReloadall(!reloadall);
        setSystemMsg(res.data.msg);
      })
      .catch((err) => {
        setBussyImporting(false);
        console.log(err);
      });
  };

  //export data to csv
  const exportData = () => {
    axiosInstance
      .get('/api/exportData')
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        <HomeHeaders />
        <Button
          sx={{ marginBottom: 1 }}
          onClick={handleSendEmail}
          disableElevation
          variant="outlined"
          color="secondary"
        >
          {!bussyImporting ? 'שליחת דוח לספקים' : '...אנא המתן טוען'}
        </Button>

        <Typography marginRight={2} variant="caption" color="blue">
          {systemMsg}
        </Typography>
        <div style={{ height: 600, width: '100%' }}>
          {sapakKatzePayment > 0 ? (
            <Typography variant="h6" color="primary">
              תשלומי ספק:
              {sapakKatzePayment.toLocaleString()}
            </Typography>
          ) : (
            <Typography variant="h6" color="primary">
              תשלומי ספק : נא לבחור רשומות
            </Typography>
          )}
          <Typography variant="h6" color="primary">
            {selection && selection.length}
          </Typography>

          <DataGrid
            loading={bussyImporting}
            components={{
              LoadingOverlay: LinearProgress,
              Toolbar: GridToolbar,
              NoResultsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  אין תוצאות
                </Stack>
              ),
            }}
            apiRef={apiRef}
            pagination
            sx={{
              fontSize: 13,
              '& .MuiTablePagination-actions': {
                direction: 'ltr !important',
              },
              '& .MuiDataGrid-footerContainer': {
                direction: 'ltr !important',
              },
            }}
            density="compact"
            rows={rows}
            columns={columns}
            pageSize={12}
            rowsPerPageOptions={[12]}
            checkboxSelection
            disableSelectionOnClick
            onFilterModelChange={(filterModel) => {
              setfilterModel(filterModel);
            }}
            onSelectionModelChange={(ids) => {
              // console.log('ids length ', ids.length)
              const selectedIDs = new Set(ids);
              const selectedRowData = rows.filter((row) =>
                selectedIDs.has(row.id.toString())
              );

              setSelection(selectedRowData);
              setSelectionModel(ids);
            }}
            selectionModel={selectionModel}
            filterModelChange={filterModel}
            {...data}
          />
          {/* <Typography variant="h6" color="primary">
                        סך מכירות {totalSales.toLocaleString()}
                    </Typography> */}
        </div>
      </Container>
    </ThemeProvider>
  );
};

function HomeHeaders({}) {
  return (
    <>
      <Typography variant="h6" color="initial">
        עלמא סחר
      </Typography>

      <Typography variant="body" color="initial">
        {' '}
        מערכת לניהול מוצרים
      </Typography>

      <Typography variant="body2" color="initial">
        דוחות ליאת
      </Typography>
    </>
  );
}

export default Liatreport;
